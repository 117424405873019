export const RoleDisplayNameToIdMap: Map<string, number> = new Map(
    [
        ['System Administrator', 1],
        ['Public', 2],
        ['Editor', 3],
        ['Read Only', 4],
        ['Technical Reviewer', 5],
        ['District Staff', 6],
    ])


export const RoleIdToDisplayNameMap: Map<number, string> = new Map(
    [
        [1, 'System Administrator'],
        [2, 'Public'],
        [3, 'Editor'],
        [4, 'Read Only'],
        [5, 'Technical Reviewer'],
        [6, 'District Staff'],
    ])


export const RoleIdAndDisplayNameArray: Array<{ text: string, value: number }> = [

    {text: 'System Administrator', value: 1},
    {text: 'Public', value: 2},
    {text: 'Editor', value: 3},
    {text: 'Read Only', value: 4},
    {text: 'Technical Reviewer', value: 5},
    {text: 'District Staff', value: 6},
]
