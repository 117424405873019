import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit,} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationService} from "src/app/services/authentication.service";
import {LinkRendererComponent} from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import {PermissionEnum} from "src/app/shared/generated/enum/permission-enum";
import {DateColumnCreatorService} from "src/app/shared/services/date-column-creator/date-column-creator.service";
import {BasicListComponent, ListPage} from "../basic/basic-list/basic-list.component";
import {ResearchPermitDto} from "src/app/shared/generated/model/models";
import {FirstDataRenderedEvent, GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {AlertService} from "src/app/shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {
    ResearchProjectStatusTypeIdToDisplayNameMap
} from "../../shared/models/enums/research-project-status-type.enum.util";
import {ResearchProjectStatusTypeEnum} from "../../shared/generated/enum/research-project-status-type-enum";
import {
    CustomDropdownFilterComponent
} from "../../shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import {FileService} from "../../shared/services/file/file.service";
import {
    ResearchProjectFileListComponent
} from "../research-project-file/research-project-file-list/research-project-file-list.component";
import {
    ResearchProjectCategoryTypeIdToDisplayNameMap
} from "../../shared/models/enums/research-project-category.enum.util";
import {RoleEnum} from "../../shared/generated/enum/role-enum";
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {BasicConfigurableModalComponent} from "../basic/basic-configurable-modal/basic-configurable-modal.component";
import {FormPage} from "../basic/shared/basic-form/basic-form.component";


@Component({
    selector: 'castateparksscp-research-permit-list',
    templateUrl: "../basic/basic-list/basic-list.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ResearchPermitListComponent extends BasicListComponent implements OnInit {
    listPage: ListPage = {
        permission: PermissionEnum.ResearchProjectRights,
        createRoute: '/public/research-projects',
        pageTitle: 'Permits',
        createButtonLabel: 'Project',
        downloadFileName: 'projects',
        customButtonLabel: "Renew Permit",
        isShowCustomButton: true
    } as ListPage;

    colIDsToExcludeFromCSV = ['SignedPermit', 'AnnualReport']
    constructor(
        public researchProjectService: ResearchProjectService,
        public fileService: FileService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.researchProjectService.researchPermitsGet();
        this.listPage.columnDefs = this.buildColumnDefinitions();
    }
    override doActionAfterSettingCurrentUser() {
        if (this.currentUser?.Role?.RoleID != RoleEnum.Public) {
            let updatedColumnDefs= this.buildColumnDefinitions(false);
            this.listPage.columnDefs = updatedColumnDefs;
            this.grid?.api?.setGridOption("columnDefs", updatedColumnDefs);
        }
    }

    processDtosGet(results: any[]) {
        results.forEach(r => {
            r['RecordType'] = [ResearchProjectStatusTypeEnum.ActivePermit, ResearchProjectStatusTypeEnum.Expired]
                .includes(r['ResearchProjectStatusTypeID']) ? 'Permit' : 'Application';
            r['Tags'] = r.Tags || [];
            r['RenewalType'] = !!r['RenewedFromResearchProjectID'] ? 'Renewal' : '1st time';
        });
        super.processDtosGet(results);
    }

    downloadPermit(event) {
        const fileRoute = `researchProjects/${event.rowData.ResearchProjectID}`;
        event.rowData['BlobName'] = event.rowData.SignedPermitFileBlobName;
        event.rowData['Name'] = event.rowData.SignedPermitFileName;
        event.rowData['ResearchProjectFileTypeID'] = ResearchProjectFileTypeEnum.SignedActivePermit;
        ResearchProjectFileListComponent.downloadFile(this.fileService, fileRoute, event.rowData);
    }

    downloadAnnualReport(event) {
        const fileRoute = `researchProjects/${event.rowData.ResearchProjectID}`;
        event.rowData['BlobName'] = event.rowData.AnnualReportFileBlobName;
        event.rowData['Name'] = event.rowData.AnnualReportFileName;
        event.rowData['ResearchProjectFileTypeID'] = ResearchProjectFileTypeEnum.AnnualReport;
        ResearchProjectFileListComponent.downloadFile(this.fileService, fileRoute, event.rowData);
    }

    public idField: string = 'ResearchProjectID';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchPermitDto> | any) => params.data.ResearchProjectID;
    public putAction = (id: number, dto: any) => {
        return this.researchProjectService.researchProjectsResearchProjectIDPut(id, dto);
    }

    override onFirstDataRendered(_$event: FirstDataRenderedEvent) {
        this.authenticationService.getCurrentUser().subscribe((user) => {
            // Skip if user is not a Technical Reviewer
            if (user.Role.RoleID != RoleEnum.TechnicalReviewer) return;
            const filter = {
                TechnicalReviewerName: {
                    "filter": user.FullName,
                    "type": "equals",
                    "filterType": "text"
                },
                ResearchProjectStatusTypeID: {
                    filtersActive: {
                        selectAll: false,
                        deselectAll: false,
                        strict: false,
                        filterOptions: {
                            "In Review": true,
                            "Awaiting Annual Report": true
                        }
                    }
                }
            };
            this.grid.api.setColumnFilterModel("TechnicalReviewerName", filter.TechnicalReviewerName);
            this.grid.api.setColumnFilterModel("ResearchProjectStatusTypeID", filter.ResearchProjectStatusTypeID);
            this.grid.api.showColumnFilter('ResearchProjectStatusTypeID')
            this.grid.api.onFilterChanged();
            setTimeout(() => {
                this.grid.api.hidePopupMenu();
            },0)

        })
    }

    buildColumnDefinitions(isPublicUser = true) {
        return [
            {
                headerName: "Application/Permit #",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ResearchProjectID,
                        LinkDisplay: params.data.ApplicationNumber,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: {
                    routerLinkGetter: (params) => params.node.data?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ActivePermit ||
                    params.node.data?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Expired ? "/public/research-project-review-and-submit/" : "/public/research-teams/",
                    inRouterLink: "/public/research-teams/"
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ApplicationNumber;
                },
                comparator: function (id1: any, id2: any) {
                    let link1 = id1.LinkDisplay;
                    let link2 = id2.LinkDisplay;
                    if (link1 < link2) {
                        return -1;
                    }
                    if (link1 > link2) {
                        return 1;
                    }
                    return 0;
                },
                suppressNavigable: true,
                width: 200,
            },
            isPublicUser ? null : {
                field: "RecordType",
                headerName: "Record Type",
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "RecordType"
                },
                editable: false,
                width: 150,
            },
            !isPublicUser ? null : {
                field: "Title",
                headerName: "Project title",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            {
                field: "ResearchProjectStatusTypeID",
                valueGetter: function(params) {
                    return ResearchProjectStatusTypeIdToDisplayNameMap.get(params.data?.ResearchProjectStatusTypeID);
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResearchProjectStatusTypeID"
                },
                headerName: "Status",
                editable: false,
                width: 225,
                cellStyle: params => {
                    let filterModel = params.api.getColumnFilterModel('ResearchProjectStatusTypeID');
                    if (!!filterModel && !filterModel.filtersActive.selectAll) {
                        return {backgroundColor: 'lightblue'};
                    }
                    return null;
                }
            },
            {
                field: "RenewalType",
                headerName: "Renewal Type",
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "RenewalType"
                },
                editable: false,
                width: 150,
            },
            isPublicUser ? null : {
                field: "ResearchProjectCategoryTypeID",
                valueGetter: function(params) {
                    return ResearchProjectCategoryTypeIdToDisplayNameMap.get(params.data?.ResearchProjectCategoryTypeID);
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ResearchProjectCategoryTypeID"
                },
                headerName: "Category",
                editable: false,
                width: 225,
                cellClass: "long-text-cell"
            },
            isPublicUser ? null : {
                field: "Tags",
                valueFormatter: function(params) {
                    return params.data?.Tags?.join(", ");
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Tags",
                    columnContainsMultipleValues: true,
                },
                headerName: "Tags",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            {
                field: "PrincipalInvestigatorName",
                headerName: "Principal Investigator",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            {
                field: "PersonInChargeOfFieldWorkName",
                headerName: "PICOF",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Project Start Date",
                "ProjectStartDate",
                "MM/dd/YYYY",
                new Map([['width', 175]])
            ),
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Project End Date",
                "ProjectEndDate",
                "MM/dd/YYYY",
                new Map([['width', 175]])
            ),
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Permit Start Date",
                "PermitStartDate",
                "MM/dd/YYYY",
                new Map([['width', 175]])
            ),
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Permit End Date",
                "PermitEndDate",
                "MM/dd/YYYY",
                new Map([['width', 175]])
            ),
            isPublicUser ? null : this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Date submitted",
                "ApplicationSubmittedDate",
                "MM/dd/YYYY",
                new Map([['width', 175]])
            ),
            isPublicUser ? null : {
                field: "CreateUserName",
                headerName: "Name of Submitter",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            isPublicUser ? null : {
                field: "OrganizationName",
                headerName: "Organization",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            isPublicUser ? null : {
                field: "DistrictNames",
                valueFormatter: function(params) {
                    return params.data?.DistrictNames?.join(", ");
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "DistrictNames",
                    columnContainsMultipleValues: true,
                },
                headerName: "Districts",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            isPublicUser ? null : {
                field: "ParkNames",
                valueFormatter: function(params) {
                    return params.data?.Parks?.join(", ");
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "ParkNames",
                    columnContainsMultipleValues: true,
                },
                headerName: "Park Units",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell'
            },
            isPublicUser ? null : {
                field: "TechnicalReviewerName",
                headerName: "Reviewer",
                editable: false,
                width: 200,
                cellClass: 'long-text-cell',
            },
            isPublicUser ? null : this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Signed Permit submitted",
                "SignedPermitSubmittedDate",
                "MM/dd/YYYY",
                new Map([['width', 200]])
            ),
            {
                headerName: "Signed Permit",
                colId: "SignedPermit",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: this.downloadPermit.bind(this),
                    icon: "download",
                    isDisabled: (params) => !params.data.SignedPermitFileName
                },
                cellStyle: (params) => {
                    return {"text-overflow": "unset"};
                },
                width: 120,
                filterable: false,
                suppressNavigable: true,
            },
            this.dateColumnCreator.createDateColumnDefWithConfigMap(
                "Annual Report Due Date",
                "PermitEndDate",
                "M/dd/YYYY",
                new Map([['width', 200]])
            ),
            !isPublicUser ? null : {
                headerName: "Annual report submitted",
                valueGetter: function(params) {
                    return !!params.data?.AnnualReportDate;
                },
                editable: false,
                width: 200,
                cellRenderer: 'agCheckboxCellRenderer',
                cellRendererParams: {disabled: true},
                cellEditor: 'agCheckboxCellEditor',
                autoHeight: true,
            },
            {
                headerName: "Annual Report",
                colId: "AnnualReport",
                cellRenderer: "buttonRenderer",
                cellRendererParams: {
                    onClick: this.downloadAnnualReport.bind(this),
                    icon: "download",
                    isDisabled: (params) => !params.data.AnnualReportFileName,
                },
                cellStyle: (params) => {
                    return {"text-overflow": "unset"};
                },
                width: 120,
                filterable: false,
                suppressNavigable: true,
            },
        ].filter( x => x !== null);
    }

    override customButtonAction() {
        this.researchProjectService.renewablePermitsGet()
            .subscribe(permits => {
                const permitOptions = permits.map(p => {
                    return {content: `${p.ApplicationNumber} (${p.Title})`, value: p.ResearchProjectID};
                });

                let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
                    height: '200px',
                    width: '400px',
                    data: {
                        ID: null,
                        dto: {ResearchProjectID: null},
                        header: 'Renew Permit',
                        showActions: true,
                        editMode: true,
                        formPage: {
                            dtoName: 'permit',
                            elements: [
                                {
                                    class: 'g-col-12',
                                    name: 'ResearchProjectID',
                                    label: 'Select a permit',
                                    type: 'filterSelect',
                                    listItems: permitOptions,
                                    listItemValue: [],
                                    required: true,
                                    selectType: 'single'
                                }
                            ],
                            createUpsertDto: (dto) => {
                                return dto;
                            },
                            post: (dto) => this.researchProjectService.researchProjectsResearchProjectIDRenewPost(dto.ResearchProjectID)
                        } as unknown as FormPage
                    }
                });

                if (dialogRef) {
                    dialogRef.afterClosed().subscribe((response) => {
                        if (response) {
                            this.refreshData();
                        }
                    });
                }
            })

    }
}
