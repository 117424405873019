import {Component, Input, OnInit} from '@angular/core';
import {ResearchProjectService} from "../../../../shared/generated/api/research-project.service";
import {
    ResearchProjectInternalFieldsDto
} from "../../../../shared/generated/model/research-project-internal-fields-dto";
import {ResearchProjectStatusTypeEnum} from "../../../../shared/generated/enum/research-project-status-type-enum";

@Component({
  selector: 'castateparksscp-returned-to-researcher-notification',
  templateUrl: './returned-to-researcher-notification.component.html',
})
export class ReturnedToResearcherNotificationComponent implements OnInit {
    @Input() researchProjectID: number;
    @Input() alwaysDisplay: boolean = false;

    internalFields: ResearchProjectInternalFieldsDto;
    constructor(private researchProjectService: ResearchProjectService) {}

    ngOnInit(): void {
        this.refreshInternalFields();
    }

    public refreshInternalFields() {
        if (this.researchProjectID) {
            this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID)
                .subscribe(result => {
                    this.internalFields = result;
                });
        }
    }

    setInternalFields(internalFields: ResearchProjectInternalFieldsDto) {
        this.internalFields = internalFields;
    }

    showNotification() {
        if (!this.alwaysDisplay && this.internalFields?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.ReturnedToResearcher) return false;
        if (!this.internalFields) return false;
        return this.internalFields.AdditionalDocumentation || this.internalFields.DPR65ASubmission || this.internalFields.DPR65BSubmission || this.internalFields.OtherInformationOrChanges || this.internalFields.AdditionalRequestedMessage;
    }
}
