import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {ResearchProjectInternalFieldsDto} from "../../../shared/generated/model/research-project-internal-fields-dto";
import {UserService} from "../../../shared/generated/api/user.service";
import {ResearchProjectStatusTypeEnum} from "../../../shared/generated/enum/research-project-status-type-enum";

@Component({
  selector: 'castateparksscp-internal-revew-internal-fields-form',
  templateUrl: './../../basic/shared/basic-form/basic-form.component.html',
  styleUrl: './internal-revew-internal-fields-form.component.css'
})
export class InternalRevewInternalFieldsFormComponent extends BasicFormComponent {
    @Input() public canEditTechnicalReview: boolean;
    @Output() public formChanged = new EventEmitter<any>();

    upsertDto: ResearchProjectInternalFieldsDto;
    technicalReviewers = [];
    technicalReviewerMap: Map<number, string> = new Map<number, string>();

    formPage: FormPage = {
        dtoName: 'research project internal field',
    } as FormPage;

    constructor(public alertService: AlertService,
                public researchProjectService: ResearchProjectService,
                public userService: UserService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => dto || {} as ResearchProjectInternalFieldsDto;
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdateInternalFieldsPut(id, upsertDto);
    }

    override ngOnInit() {
        this.userService.usersTechnicalReviewersGet().subscribe((technicalReviewers) => {
            this.technicalReviewers = technicalReviewers.map(userDto => {
                this.technicalReviewerMap.set(userDto.UserID, userDto.FullName);
                return {content: userDto.FullName, value: userDto.UserID};
            });
            this.refreshFormElements();
        });
        super.ngOnInit();
    }

    override buildFormElements(_isAccessRestricted): FormElement[] {
        let mappedDtoValue = this.mapDtoValueToListItem('TechnicalReviewerUserID');
        return [
            {
                class: 'g-col-4',
                name: 'TechnicalReviewerUserID',
                label: 'Assignee',
                selectDisplayFieldName: 'TechnicalReviewerFullName',
                type: 'combobox',
                listItems: this.technicalReviewers,
                listItemValue: mappedDtoValue,
                displayFilterSelectedItems: true,
                requiredValidated: true,
                disabled: !this.canEditTechnicalReview,
                onChange: () => this.formChanged.emit(),
            }
        ] as FormElement[];
    }

    override mapDtoValueToListItem(property: string, _displayField: string = null): null | any {
        if (!this.upsertDto?.[property]) return null;
        return {'content': this.technicalReviewerMap.get(this.upsertDto[property]), 'selected': true, value: this.upsertDto[property]};
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormElements();
    }
}
