import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from '../../basic/basic-list/basic-list.component';
import {PermissionEnum} from 'src/app/shared/generated/enum/permission-enum';
import {Router} from "@angular/router";
import {AuthenticationService} from 'src/app/services/authentication.service';
import {DateColumnCreatorService} from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import {AlertService} from 'src/app/shared/services/alert.service';
import {
    InLineEditButtonsRendererComponent
} from 'src/app/shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component';
import {GetRowIdFunc, GetRowIdParams} from 'ag-grid-community';
import {MatDialog} from '@angular/material/dialog';
import {ResearchProjectContactService} from "../../../shared/generated/api/research-project-contact.service";
import {ResearchProjectContactUpsertDto} from "../../../shared/generated/model/research-project-contact-upsert-dto";
import {ResearchProjectContactTypeEnum} from "../../../shared/generated/enum/research-project-contact-type-enum";
import {RequiredHeaderComponent} from "../../../shared/components/ag-grid/required-header/required-header.component";

@Component({
    selector: 'castateparksscp-research-team-additional-participant-list',
    templateUrl: '../../basic/basic-list/basic-list.component.html',
    styleUrl: './research-team-additional-participants-list.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResearchTeamAdditionalParticipantsListComponent extends BasicListComponent implements OnInit {
    @Input() researchProjectID: number;

    isH4Title: boolean = true;

    listPage: ListPage = {
        permission: PermissionEnum.ResearchProjectRights,
        pageTitle: 'Additional Participants',
        createButtonLabel: 'Participant',
        downloadFileName: 'AdditionalParticipants',
        isShowAddButton: true,
        iSShowTitle: true,
        gridTableStyle: 'height: 400px',
        headerText: "<cds-text-label>Click on save icon (<span class=\"text-muted fas fa-save\"></span>) to save any grid edits.</cds-text-label>",
        headerTextClass: 'copy-4',
        isShowHeader: true,
        headerTextNoMargin: true
    } as ListPage;

    useInGridEdit: boolean = true;
    private getCanEdit() { return this.canEdit };

    constructor(
        public researchProjectContactService: ResearchProjectContactService,
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.researchProjectContactService
            .researchProjectContactsResearchProjectResearchProjectIDResearchProjectContactTypeResearchProjectContactTypeIDGet(this.researchProjectID, ResearchProjectContactTypeEnum.AdditionalParticipant);
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                            editableCallback: this.getCanEdit.bind(this)
                        },
                    };
                },
                width: 100,
                pinned: 'left',
                editable: false,
            },
            {
                headerName: "First Name",
                field: "FirstName",
                flex: 1,
                required: true,
                headerComponentParams: { required: true },
            },
            {
                headerName: "Last Name",
                field: "LastName",
                flex: 1,
                required: true,
                headerComponentParams: { required: true },
            },
            {
                headerName: "Phone Number",
                field: "PhoneNumber1",
                flex: 1,
            },
            {
                headerName: "Email",
                field: "Email",
                flex: 1,
                required: true,
                headerComponentParams: { required: true },
            },
            {
                headerName: "Title",
                field: "Title",
                flex: 1,
            },
            {
                headerName: "Comments",
                field: "Comment",
                flex: 1,
            },
        ]
    }

    public idField: string = 'ResearchProjectContactID';
    public startEditingColKey: string = 'FirstName';

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchProjectContactUpsertDto> | any) => params.data.ResearchProjectContactID;
    public putAction = (id: number, dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        dto.ResearchProjectContactTypeID = ResearchProjectContactTypeEnum.AdditionalParticipant;
        return this.researchProjectContactService.researchProjectContactsResearchProjectContactIDPut(id, dto);
    }
    public postAction = (dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        dto.ResearchProjectContactTypeID = ResearchProjectContactTypeEnum.AdditionalParticipant;
        return this.researchProjectContactService.researchProjectContactsPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.researchProjectContactService.researchProjectContactsResearchProjectContactIDDelete(id);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gridComponents['agColumnHeader'] = RequiredHeaderComponent;
    }

}
