import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit, Output,
    ViewChild,
} from "@angular/core";
import {Router} from "@angular/router";
import {AgGridAngular} from "ag-grid-angular";
import {ColDef, FirstDataRenderedEvent, GetRowIdFunc} from "ag-grid-community";
import {Observable, Subscription} from "rxjs";
import {AuthenticationService} from "src/app/services/authentication.service";
import {UserDto} from "src/app/shared/generated/model/user-dto";
import {RightsEnum} from "src/app/shared/models/enums/rights.enum";
import {DateColumnCreatorService} from "src/app/shared/services/date-column-creator/date-column-creator.service";
import {AlertService} from "src/app/shared/services/alert.service";
import {AlertContext} from "src/app/shared/models/enums/alert-context.enum";
import {Alert} from "src/app/shared/models/alert";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialog} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import {ButtonRendererComponent} from "../../../shared/components/ag-grid/button-renderer/button-renderer.component";
import {BasicModalConfiguration} from "../basic-modal/basic-modal.component";

@Component({
    selector: 'castateparksscp-basic-list',
    templateUrl: './basic-list.component.html',
    styleUrl: './basic-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicListComponent implements OnInit {
    @ViewChild("Grid") grid: AgGridAngular;

    @Input() listPage: ListPage;
    @Input() entryRequired: boolean = false;
    @Input() disabled: boolean = false;
    @Output() editEvent: EventEmitter<any> = new EventEmitter<any>();

    public currentUser: UserDto;
    public dtos: Array<any>;
    public useInGridEdit: boolean = false;

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    public getDto: Function;

    user: Subscription;
    getRequest: Subscription;

    public idField = null;
    public startEditingColKey = null;
    public getRowId: GetRowIdFunc = null;
    putAction: (id: number, dto: any) => Observable<any>;
    postAction: (dto: any) => Observable<any>;
    deleteAction: (id: number) => Observable<void>;
    parseDto: (x) => Function;

    isCancelButtonClicked: boolean = false;
    isSkipCachingRowValue: boolean = false;
    rowIndex: number = null;
    id: number = null;
    cache: any = null;
    addNewMode: boolean = false;
    listAsTile: boolean = false;
    gridComponents: any;
    isH4Title: boolean = false;
    requiredFields = [];
    hasListViewPadding: boolean = true;
    colIDsToExcludeFromCSV = [];
    modalOpen: boolean = false;
    constructor(
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.doActionAfterSettingCurrentUser();
                this.cdr.markForCheck();
            });
        this.columnDefs = this.listPage.columnDefs;
        this.columnDefs.forEach(colDef => {
            if (!colDef.cellStyle) {
                colDef.cellStyle = params => {
                    if (!!params.api.getColumnFilterModel(colDef.field)) {
                        return {backgroundColor: 'lightblue'};
                    }
                    return null;
                }
            }
            if (colDef.editable == undefined) {
                colDef.editable = () => {
                    return this.canEdit;
                }
            }
        })
        this.defaultColDef = !!this.listPage.defaultColDef
            ? this.listPage.defaultColDef
            : {
                sortable: true,
                filter: true,
                resizable: true,
                floatingFilter: true,
                suppressMenu: true,
            };

        if (this.listAsTile) {
            this.loadData();
        }
        this.gridComponents = {
            buttonRenderer: ButtonRendererComponent,
        };
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        if (this.getRequest) {
            this.getRequest.unsubscribe();
        }
    }

    navigateToCreatePage() {
        this.router.navigate([this.listPage.createRoute]);
    }

    onGridReady(gridEvent) {
        this.grid.api.showLoadingOverlay();
        this.loadData();
    }

    public loadData() {
        if (this.listPage.dtosGetter) {
            this.getRequest = this.listPage
                .dtosGetter()
                .subscribe((results) => {
                    this.processDtosGet(results);
                });
        }
    }

    public processDtosGet(results: any[]) {
        this.rowData = results;
        if (!this.listAsTile) this.grid.api.hideOverlay();
        this.dtos = results;
        this.cdr.markForCheck();
    }

    get canCreate(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            this.listPage.permission,
            RightsEnum.Create
        );
    }

    get canEdit(): boolean {
        return !this.disabled &&
            this.authenticationService.hasPermission(
            this.currentUser,
            this.listPage.permission,
            RightsEnum.Update
        );
    }

    get canDelete(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            this.listPage.permission,
            RightsEnum.Delete
        );
    }

    save(params) {
        this.grid.api.stopEditing();
    }

    cancel(params) {
        this.isCancelButtonClicked = true;
        this.stopEditing();
    }

    delete(params) {
        this.stopEditing();
        this.id = params.rowData[this.idField];
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: `Delete ${this.listPage.pageTitle}`,
                text: `Are you sure you want to delete?`,
            }
        });

        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.deleteAction(this.id).subscribe(() => {
                    this.alertService.pushAlert(new Alert(`${this.listPage.pageTitle} was successfully deleted.`, AlertContext.Success, true));
                    this.editEvent.emit();
                    this.refreshData();
                }, error => {
                    this.alertService.pushAlert(new Alert(`There was an error deleting the ${this.listPage.pageTitle}. Please try again.`, AlertContext.Danger, true));
                });
            }
        });
    }

    refreshData() {
        this.getRequest = this.listPage
            .dtosGetter()
            .subscribe((results) => {
                this.processDtosGet(results);
            });
    }

    edit(params) {
        this.stopEditing();
        this.rowIndex = params.node.rowIndex;
        this.startEditing();
    }

    startEditing() {
        this.grid.api.startEditingCell({
            rowIndex: this.rowIndex,
            colKey: this.startEditingColKey,
        });
    }

    sortChanged(event) {
        this.grid.api.redrawRows();
    }

    filterChanged(event) {
        this.grid.api.redrawRows();
    }

    stopEditing() {
        this.rowIndex = null;
        this.grid.api.stopEditing();
    }

    onRowEditingStarted(event) {
        if (!this.isSkipCachingRowValue) {
            this.id = event.data[this.idField];
            this.cache = JSON.stringify(event.data);
            this.rowIndex = event.node.rowIndex;
        }
        this.isSkipCachingRowValue = false;
    }

    onRowEditingStopped(event) {
        let saveRowIndex = this.rowIndex;
        this.rowIndex = null;

        if (this.isCancelButtonClicked) {
            if (this.addNewMode) {
                this.rowData.shift();
                this.grid.api.setGridOption("rowData", this.rowData);
                this.addNewMode = false;
                this.grid.api.redrawRows();
            } else {
                this.revertRow(event);
            }
            this.isCancelButtonClicked = false;
            return;
        }

        const saveDto = this.preprocessSaveDto(event.data);
        this.getSaveAction(saveDto).subscribe({
            next: result => {
                this.alertService.pushAlert(new Alert("Edit was successful.", AlertContext.Success));
                this.editEvent.emit();
                this.refreshData();
                if (!!this.addNewMode) this.addNewMode = false;
            },
            error: error => {
                let errMsg = '';
                if (error.error instanceof String) {
                    errMsg = `There was an error: ${!!error.error ? error.error : ''}`;
                } else if (error.error.errors instanceof Object || error.error instanceof Object) {
                    let err = error.error.errors || error.error;
                    Object.values(err).forEach((value, index) => {
                        if (value instanceof Array) {
                            errMsg += value[0]
                        }
                    });
                } else {
                    errMsg = 'An error has occurred.';
                }
                this.alertService.pushAlert(new Alert(errMsg, AlertContext.Danger));
                this.isSkipCachingRowValue = true;
                this.rowIndex = saveRowIndex;
                this.startEditing();
            }
        });
    }

    getSaveAction(data: any): Observable<any> {
        if (!!this.id) return this.putAction(this.id, data);
        return this.postAction(data);
    }

    preprocessSaveDto(dto) {
        return dto;
    }

    revertRow(event) {
        const rowNode = event.api.getRowNode(this.id);
        rowNode.setData(JSON.parse(this.cache));
    }

    addNewRow() {
        if (this.addNewMode) return;
        this.addNewMode = true;
        const newRow = {};
        this.id = null;
        this.rowData.unshift(newRow);
        this.grid.api.setGridOption("rowData", this.rowData);
        this.grid.api.redrawRows();
        this.rowIndex = 0;
        this.grid.api.startEditingCell({
            rowIndex: this.rowIndex,
            colKey: this.startEditingColKey,
        });
    }

    getThisRowIndex() {
        return this.rowIndex;
    }

    getTileHeader(row: any): string {
        return '';
    }

    getTileSubHeader(row: any): string {
        return '';
    }

    getTileContent(row: any): string {
        return '';
    }

    getTileLink(row: any): string {
        return '';
    }

    isShowTitle(): boolean {
        if (this.listPage.iSShowTitle == undefined) return true;
        return this.listPage.iSShowTitle;
    }

    isShowButtonGroup(): boolean {
        if (this.listPage.iSShowButtonGroup == undefined) return true;
        return this.listPage.iSShowButtonGroup;
    }

    getGridTableStyle(): string {
        if (this.listPage.gridTableStyle == undefined) return 'height: 800px';
        return this.listPage.gridTableStyle;
    }

    isShowHeader(): boolean {
        if (this.listPage.isShowHeader == undefined) return true;
        if (!!this.listPage.headerText) return false;
        return this.listPage.isShowHeader;
    }

    isShowHeaderWithText(): boolean {
        if (this.listPage.isShowHeader == undefined) return false;
        return this.listPage.isShowHeader && !!this.listPage.headerText;
    }

    doActionAfterSettingCurrentUser() {}

    onFirstDataRendered($event: FirstDataRenderedEvent<any>) {}

    customButtonAction() {
    }
}

export interface ListPage {
    modalConfig: BasicModalConfiguration;
    addButtonCdsButton?: string;
    customButtonCdsButton?: string;
    customButtonLabel: string;
    isShowCustomButton: boolean;
    columnDefs: Array<any>;
    customButtonDefs: Array<ButtonDef>;
    defaultColDef: {
        sortable: boolean;
        filter: boolean;
        resizable: boolean;
        floatingFilter: boolean;
        suppressMenu: boolean;
    },
    dtosGetter: () => Observable<Array<any>>;
    createRoute: string;
    permission: any;
    pageTitle: string;
    createButtonLabel: string;
    downloadFileName: string;
    isShowAddButton: boolean;
    iSShowTitle: boolean;
    iSShowButtonGroup: boolean;
    isShowHeader: boolean;
    isHideDownload: boolean;
    gridTableStyle: string;
    hints: string;
    divId?: string;
    headerText?: string;
    headerTextNoMargin?: boolean;
    headerTextClass?: string;
}

export interface ButtonDef {
    canDo: Function;
    buttonLabel: string;
    icon: string;
    onclick: Function;
}
