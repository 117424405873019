<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="researchProjectDto"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <h2>Project Information</h2>
        <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>

        <castateparksscp-research-project-information-form
            #researchProjectInformationForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="researchProjectInformationSaved($event)"
            (cancelEditModeChange)="cancelEditResearchProject()"></castateparksscp-research-project-information-form>

        <castateparksscp-research-project-field-investigation-occurrence-list
            #researchProjectFieldInvestigationOccurrenceList
            [entryRequired]="true"
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        >
        </castateparksscp-research-project-field-investigation-occurrence-list>

        <castateparksscp-research-project-file-list-manager
            [title]="'Project schedule additional documentation'"
            [description]="'If you have a more complex project schedule you can upload supporting documents.'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.ProjectScheduleDocumentation"
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        ></castateparksscp-research-project-file-list-manager>

        <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right;">
            <button cdsButton size="xl" (click)="saveAndContinue()">Save and Continue
                <castateparksscp-right-arrow></castateparksscp-right-arrow>
            </button>
        </div>
    </div>

</div>
