<div class="g-col-2" style="margin-inline: 15px;  margin-top:45px;">
    <cds-progress-indicator
        [skeleton]="skeleton"
        [steps]="steps"
        [orientation]="orientation"
        [current]="current"
        (stepSelected)="stepSelected($event)"
        [spacing]="spacing">
    </cds-progress-indicator>
    <div style="padding: 1rem; margin-top: 1rem; margin-bottom: 1rem; background: #d0e2ff; border: 1px dotted #0f62fe">
        <p style="font-size: smaller"><strong>Status:</strong> {{getResearchProjectStatus()}}</p>
    </div>

    <ng-container *ngIf="sidebarActionButtonsAvailable()">
        <button type="button" style="margin-bottom: .5rem; width: 100%" [disabled]="cannotSendBackToResearcher()" cdsButton="secondary" size="md" (click)="returnToResearcher()">Return to Researcher</button>
        <button type="button" style="margin-bottom: 6rem; width: 100%" cdsButton="primary" size="md" (click)="navigateToApproveSection()">Approve Permit</button>
        <button type="button" style="width: 100%; margin-bottom: 1rem;" [disabled]="isRejectButtonDisabled()" cdsButton="tertiary" size="md" (click)="reject()">Reject Application</button>
    </ng-container>
</div>
