<div class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">Invite User</h1>
    </div>

    <form class="form grid-12" (ngSubmit)="onSubmit(inviteUserForm)" #inviteUserForm="ngForm">
        <div class="g-col-12">
            <cds-text-label>
                First Name*
                <input
                    name="FirstName"
                    [required]="true"
                    [(ngModel)]="model.FirstName"
                    cdsText>
            </cds-text-label>
        </div>

        <div class="g-col-12">
            <cds-text-label>
                Last Name*
                <input
                    name="LastName"
                    [required]="true"
                    [(ngModel)]="model.LastName"
                    cdsText>
            </cds-text-label>
        </div>

        <div class="g-col-12">
            <cds-text-label>
                Email*
                <input
                    name="Email"
                    [required]="true"
                    [(ngModel)]="model.Email"
                    cdsText>
            </cds-text-label>
        </div>

        <div class="g-col-12">
            <cds-select
                name="RoleID"
                [label]="'Role*'"
                [(ngModel)]="model.RoleID"
            >
                <option value="default" disabled selected hidden>Choose a Role</option>
                <ng-container *ngFor="let option of roleSelectOptions">
                    <option value="{{option.value}}">{{ option.text }}</option>
                </ng-container>
            </cds-select>
        </div>

        <div class="required-note g-col-8">
            <b>*</b> Required field
        </div>

        <div class="g-col-12 actions-bar" style="margin-top: 1rem">
            <esa-material-button
                type="primary"
                label="Save"
                icon="save"
                [tooltip]="!inviteUserForm.form.valid ? 'You are missing requried fields.' : ''"
                (click)="onSubmit(inviteUserForm)"
                [disabled]="!inviteUserForm.form.valid || isLoadingSubmit"
                style="margin-right: 1rem">
            </esa-material-button>
            <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancel()"></esa-material-button>
        </div>
    </form>
</div>
