<div [class.list-view]="hasListViewPadding" [attr.id]="listPage?.divId">
    <div class="page-header" *ngIf="isShowHeader()">
        <div class="actions-bar flex-between">
            <ng-container *ngIf="isShowTitle()">
                <ng-container *ngIf="!isH4Title">
                    <h1 class="page-title">{{ listPage.pageTitle }}<span *ngIf="entryRequired" class="custom-required"></span></h1>
                </ng-container>
                <ng-container *ngIf="isH4Title">
                    <h4>{{ listPage.pageTitle }}<span *ngIf="entryRequired" class="custom-required"></span></h4>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="listPage.customButtonDefs">
                <ng-container *ngFor="let buttonDef of listPage.customButtonDefs">
                    <esa-material-button
                        *ngIf="buttonDef.canDo()"
                        label="{{buttonDef.buttonLabel}}"
                        aria-label="{{buttonDef.buttonAriaLabel}}"
                        icon="{{buttonDef.icon}}"
                        type="primary"
                        (click)="buttonDef.onclick()">
                    </esa-material-button>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="listPage.isShowAddButton || listPage.isShowCustomButton">
                <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right;">
                    <button (click)="customButtonAction()" *ngIf="!useInGridEdit && canCreate && listPage.isShowCustomButton"
                            [disabled]="!canEdit" [cdsButton]="listPage.customButtonCdsButton|| 'primary'"
                            style="margin-right:5px">{{ listPage.customButtonLabel }}
                    </button>
                    <button (click)="navigateToCreatePage()" *ngIf="!useInGridEdit && canCreate && listPage.isShowAddButton" [disabled]="!canEdit"
                            [cdsButton]="listPage.addButtonCdsButton || 'primary'">Add {{ listPage.createButtonLabel }}
                    </button>
                    <button (click)="addNewRow()" *ngIf="useInGridEdit && canCreate && listPage.isShowAddButton" [disabled]="!canEdit"
                            [cdsButton]="listPage.addButtonCdsButton || 'primary'">Add {{ listPage.createButtonLabel }}
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="page-header" *ngIf="isShowHeaderWithText()">
        <div class="actions-bar flex-between">
            <ng-container *ngIf="isShowTitle()">
                <ng-container *ngIf="!isH4Title">
                    <h1 class="page-title">{{ listPage.pageTitle }}<span *ngIf="entryRequired" class="custom-required"></span></h1>
                </ng-container>
                <ng-container *ngIf="isH4Title">
                    <h4>{{ listPage.pageTitle }}<span *ngIf="entryRequired" class="custom-required"></span></h4>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="listPage.customButtonDefs">
                <ng-container *ngFor="let buttonDef of listPage.customButtonDefs">
                    <esa-material-button
                        *ngIf="buttonDef.canDo()"
                        label="{{buttonDef.buttonLabel}}"
                        aria-label="{{buttonDef.buttonAriaLabel}}"
                        icon="{{buttonDef.icon}}"
                        type="primary"
                        (click)="buttonDef.onclick()">
                    </esa-material-button>
                </ng-container>
            </ng-container>
        </div>
        <div class="copy {{listPage?.headerTextClass || 'copy-3'}}" [ngClass]="{'m-0': !!listPage.headerTextNoMargin, 'm-y': !listPage.headerTextNoMargin}">
            <span [innerHTML]="listPage.headerText"></span>
        </div>
        <ng-container *ngIf="listPage.isShowAddButton || listPage.isShowCustomButton">
            <div class="grid-12">
                <div class="g-col-12" style="display: flex; flex-direction: row-reverse">
                    <button (click)="navigateToCreatePage()" *ngIf="!useInGridEdit && canCreate && listPage.isShowAddButton" [disabled]="!canEdit"
                            [cdsButton]="listPage.addButtonCdsButton || 'primary'">Add {{ listPage.createButtonLabel }}
                    </button>
                    <button (click)="addNewRow()" *ngIf="useInGridEdit && canCreate && listPage.isShowAddButton" [disabled]="!canEdit"
                            [cdsButton]="listPage.addButtonCdsButton || 'primary'">Add {{ listPage.createButtonLabel }}
                    </button>
                    <button (click)="customButtonAction()" *ngIf="!useInGridEdit && canCreate && listPage.isShowCustomButton"
                            [disabled]="!canEdit" [cdsButton]="listPage.customButtonCdsButton|| 'primary'"
                            style="margin-left:5px">{{ listPage.customButtonLabel }}
                    </button> {{listPage.addButtonCdsButton}}
                </div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isShowButtonGroup()">
        <div class="filter-btn-group align-right mb-0">
            <castateparksscp-clear-grid-filters-button class="mr-2"
                                                       [grid]="grid"></castateparksscp-clear-grid-filters-button>
            <castateparksscp-csv-download-button *ngIf="!listPage.isHideDownload" [grid]="grid" fileName="{{listPage.downloadFileName}}"
                                                 colIDsToExclude="{{!!colIDsToExcludeFromCSV?colIDsToExcludeFromCSV:[]}}"></castateparksscp-csv-download-button>
        </div>
    </ng-container>

    <ng-container *ngIf="!listAsTile">
        <div class="table-responsive" style="{{getGridTableStyle()}}">
            <ag-grid-angular
                #Grid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [getRowId]="getRowId"
                [editType]="'fullRow'"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                [readOnlyEdit]="false"
                (rowEditingStarted)="onRowEditingStarted($event)"
                (rowEditingStopped)="onRowEditingStopped($event)"
                (sortChanged)="sortChanged($event)"
                (filterChanged)="filterChanged($event)"
                [components]="gridComponents"
                (gridReady)="onGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)">
            </ag-grid-angular>
        </div>
    </ng-container>

    <ng-container *ngIf="listAsTile && rowData">
        <div class="grid-12">
            <ng-container *ngFor="let row of rowData">
                    <cds-clickable-tile [href]="getTileLink(row)" class="g-col-6">
                        <div>{{ getTileSubHeader(row) }}</div>
                        <div><h5>{{ getTileHeader(row) }}</h5></div>
                        <div>{{ getTileContent(row) }}</div>
                    </cds-clickable-tile>
            </ng-container>
        </div>
    </ng-container>

    <cds-text-label>{{listPage.hints}}</cds-text-label>
    <div *ngIf="entryRequired && !rowData?.length" style="display:flex; align-items: center">
        <span style="display: inline-block" class="text-info smaller">Requires at least one entry.</span>
    </div>

</div>

<castateparksscp-basic-modal *ngIf="listPage?.modalConfig" [open]="modalOpen" [config]="listPage.modalConfig" (close)="modalOpen = false"></castateparksscp-basic-modal>


