/**
 * CAStateParksSCP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class ResearchProjectContactUpsertDto { 
    ResearchProjectContactID?: number;
    ResearchProjectID?: number;
    ContactID?: number;
    ResearchProjectContactTypeID?: number;
    FirstName?: string;
    LastName?: string;
    OrganizationName?: string;
    PhoneNumber1?: string;
    Email?: string;
    AddressLine1?: string;
    AddressLine2?: string;
    City?: string;
    StateID?: number;
    CountryID?: number;
    PostalCode?: string;
    Title?: string;
    Comment?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
