import {Component, OnInit, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {StateIdAndDisplayNameArrayUS,} from 'src/app/shared/models/enums/state.enum.util';
import {AlertService} from 'src/app/shared/services/alert.service';
import {CurrentResearchProjectContactService} from "../../../../services/current-research-project-contact.service";
import {ResearchProjectContactService} from "../../../../shared/generated/api/research-project-contact.service";
import {ResearchProjectContactUpsertDto} from 'src/app/shared/generated/model/research-project-contact-upsert-dto';
import {CountryIdAndDisplayNameArray} from "../../../../shared/models/enums/country.enum.util";
import {ContactDto} from "../../../../shared/generated/model/contact-dto";
import {ContactFormComponent} from "./contact-form.component";
import {Observable, of, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {
    ResearchTeamCreatePublicComponent
} from "../../../research-team/research-team-create-public/research-team-create-public.component";

@Component({
    selector: 'castateparksscp-contact-form-person-in-charge-of-field-work',
    templateUrl: '../../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ContactFormPersonInChargeOfFieldWorkComponent extends BasicFormComponent implements OnInit {
    upsertDto: ResearchProjectContactUpsertDto;

    stateOptions: Array<{ text: string, value: number }> = StateIdAndDisplayNameArrayUS;
    countryOptions: Array<{ text: string, value: number }> = CountryIdAndDisplayNameArray;

    formPage: FormPage = {
        dtoName: 'contact'
    } as FormPage;

    constructor(public currentResearchProjectContactService: CurrentResearchProjectContactService,
                public researchProjectContactService: ResearchProjectContactService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => this.currentResearchProjectContactService.createResearchProjectContactDto(dto);
        this.formPage.post = (upsertDto) => this.researchProjectContactService.researchProjectContactsPost(upsertDto);
        this.formPage.put = (id, upsertDto) => this.researchProjectContactService.researchProjectContactsResearchProjectContactIDPut(id, upsertDto);
    }

    public replaceContactInfo(contactDto: ContactDto) {
        this.dto.OrganizationName = contactDto.OrganizationName;
        this.dto.FirstName = contactDto.FirstName;
        this.dto.LastName = contactDto.LastName;
        this.dto.AddressLine1 = contactDto.AddressLine1;
        this.dto.AddressLine2 = contactDto.AddressLine2;
        this.dto.PhoneNumber1 = contactDto.PhoneNumber1;
        this.dto.Email = contactDto.Email;
        this.dto.City = contactDto.City;
        this.dto.StateID = contactDto.StateID;
        this.dto.CountryID = contactDto.CountryID;
        this.dto.PostalCode = contactDto.PostalCode;
        this.setForm();
    }

    override buildFormElements(isAccessRestricted: boolean = false): FormElement[] {
        return [
            {
                class: 'g-col-6',
                name: 'FirstName',
                label: 'First Name',
                requiredValidated: true,
                type: 'text',
                disabled: isAccessRestricted
            },
            {
                class: 'g-col-6',
                name: 'LastName',
                label: 'Last Name',
                requiredValidated: true,
                type: 'text',
                disabled: isAccessRestricted

            },
            {
                class: 'g-col-6',
                name: 'PhoneNumber1',
                label: 'Phone Number',
                requiredValidated: true,
                fieldDefinitionType: 'PhoneNumber',
                type: 'phoneNumber',
            },
            {
                class: 'g-col-6',
                name: 'Email',
                label: 'Email',
                requiredValidated: true,
                fieldDefinitionType: 'Email',
                type: 'text',
                disabled: isAccessRestricted
            },
            {
                class: 'g-col-6',
                name: 'AddressLine1',
                label: 'Mailing Address Line 1',
                requiredValidated: true,
                fieldDefinitionType: 'AddressLine1',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'AddressLine2',
                label: 'Address Line 2',
                fieldDefinitionType: 'AddressLine2',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'CountryID',
                label: 'Country',
                fieldDefinitionType: 'Country',
                requiredValidated: true,
                selectDisplayFieldName: 'CountryDisplayName',
                type: 'select',
                selectOptions: this.countryOptions,
                onChange: ContactFormComponent.updateStateList,
            },
            {
                class: 'g-col-6',
                name: 'City',
                label: 'City',
                requiredValidated: true,
                fieldDefinitionType: 'City',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'StateID',
                label: 'State',
                fieldDefinitionType: 'State',
                requiredValidated: true,
                selectDisplayFieldName: 'StateDisplayName',
                type: 'select',
                selectOptions: this.stateOptions,
            },
            {
                class: 'g-col-6',
                name: 'PostalCode',
                label: 'Zip Code',
                requiredValidated: true,
                fieldDefinitionType: 'PostalCode',
                type: 'text',
            },
        ] as FormElement[];
    }

    refreshFormElements(isAccessRestricted: boolean = false) {
        super.refreshFormElements(isAccessRestricted);
        if (this.upsertDto?.CountryID) {
            ContactFormComponent.updateStateList(this, !this.upsertDto.StateID);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormElements();
    }
    override getSaveFormRequest(): Observable<any> {
        return super.getSaveFormRequest().pipe(catchError(err => of({
            error: this.getFormErrorFromHttpErrorResponse(err),
            form: ResearchTeamCreatePublicComponent.FW_FORM_LABEL
        })));
    }
}

export interface ChildFormError {
    error: any;
    form: string;
}
