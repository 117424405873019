/**
 * CAStateParksSCP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdditionalInformationRequestedCreateDto } from '../model/additional-information-requested-create-dto';
import { RejectPermitDto } from '../model/reject-permit-dto';
import { ResearchPermitDto } from '../model/research-permit-dto';
import { ResearchProjectAdditionalDocumentationUpsertDto } from '../model/research-project-additional-documentation-upsert-dto';
import { ResearchProjectAssociatedDistrictContactDto } from '../model/research-project-associated-district-contact-dto';
import { ResearchProjectDataCollectionUpsertDto } from '../model/research-project-data-collection-upsert-dto';
import { ResearchProjectDateUpsertDto } from '../model/research-project-date-upsert-dto';
import { ResearchProjectDto } from '../model/research-project-dto';
import { ResearchProjectInformationUpsertDto } from '../model/research-project-information-upsert-dto';
import { ResearchProjectInternalFieldsDto } from '../model/research-project-internal-fields-dto';
import { ResearchProjectInternalNotesDto } from '../model/research-project-internal-notes-dto';
import { ResearchProjectRenewedFromDto } from '../model/research-project-renewed-from-dto';
import { ResearchProjectResearchTeamUpsertDto } from '../model/research-project-research-team-upsert-dto';
import { ResearchProjectSuccessDto } from '../model/research-project-success-dto';
import { ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto } from '../model/research-project-update-person-in-charge-of-field-work-flag-dto';
import { ResearchProjectUpdateUserIsThePrincipalInvestigatorDto } from '../model/research-project-update-user-is-the-principal-investigator-dto';
import { ResearchProjectUpsertDto } from '../model/research-project-upsert-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class ResearchProjectService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renewablePermitsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectDto>>;
    public renewablePermitsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectDto>>>;
    public renewablePermitsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectDto>>>;
    public renewablePermitsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectDto>>(`${this.basePath}/renewablePermits`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchPermitsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchPermitDto>>;
    public researchPermitsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchPermitDto>>>;
    public researchPermitsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchPermitDto>>>;
    public researchPermitsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchPermitDto>>(`${this.basePath}/researchPermits`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectDto>>;
    public researchProjectsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectDto>>>;
    public researchProjectsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectDto>>>;
    public researchProjectsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectDto>>(`${this.basePath}/researchProjects`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsPost(researchProjectUpsertDto?: ResearchProjectUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsPost(researchProjectUpsertDto?: ResearchProjectUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsPost(researchProjectUpsertDto?: ResearchProjectUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsPost(researchProjectUpsertDto?: ResearchProjectUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResearchProjectDto>(`${this.basePath}/researchProjects`,
            researchProjectUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDApprovePut(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectInternalFieldsDto>;
    public researchProjectsResearchProjectIDApprovePut(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDApprovePut(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDApprovePut(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDApprovePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<ResearchProjectInternalFieldsDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/approve`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDAssociatedDistrictContactsGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ResearchProjectAssociatedDistrictContactDto>>;
    public researchProjectsResearchProjectIDAssociatedDistrictContactsGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResearchProjectAssociatedDistrictContactDto>>>;
    public researchProjectsResearchProjectIDAssociatedDistrictContactsGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResearchProjectAssociatedDistrictContactDto>>>;
    public researchProjectsResearchProjectIDAssociatedDistrictContactsGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDAssociatedDistrictContactsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ResearchProjectAssociatedDistrictContactDto>>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/associatedDistrictContacts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDCompleteGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDCompleteGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDCompleteGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDCompleteGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDCompleteGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/complete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDDelete(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectsResearchProjectIDDelete(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectsResearchProjectIDDelete(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectsResearchProjectIDDelete(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDFinalSubmitPut(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDFinalSubmitPut(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDFinalSubmitPut(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDFinalSubmitPut(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDFinalSubmitPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/finalSubmit`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDInternalFieldsGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectInternalFieldsDto>;
    public researchProjectsResearchProjectIDInternalFieldsGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDInternalFieldsGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDInternalFieldsGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDInternalFieldsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectInternalFieldsDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/internalFields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDInternalNotesGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectInternalNotesDto>;
    public researchProjectsResearchProjectIDInternalNotesGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectInternalNotesDto>>;
    public researchProjectsResearchProjectIDInternalNotesGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectInternalNotesDto>>;
    public researchProjectsResearchProjectIDInternalNotesGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDInternalNotesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectInternalNotesDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/internalNotes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectInternalNotesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDInternalNotesPut(researchProjectID: number, researchProjectInternalNotesDto?: ResearchProjectInternalNotesDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectInternalNotesDto>;
    public researchProjectsResearchProjectIDInternalNotesPut(researchProjectID: number, researchProjectInternalNotesDto?: ResearchProjectInternalNotesDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectInternalNotesDto>>;
    public researchProjectsResearchProjectIDInternalNotesPut(researchProjectID: number, researchProjectInternalNotesDto?: ResearchProjectInternalNotesDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectInternalNotesDto>>;
    public researchProjectsResearchProjectIDInternalNotesPut(researchProjectID: number, researchProjectInternalNotesDto?: ResearchProjectInternalNotesDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDInternalNotesPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectInternalNotesDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/internalNotes`,
            researchProjectInternalNotesDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/markAwaitingAnnualReport`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectUpsertDto?: ResearchProjectUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectUpsertDto?: ResearchProjectUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectUpsertDto?: ResearchProjectUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDPut(researchProjectID: number, researchProjectUpsertDto?: ResearchProjectUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}`,
            researchProjectUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param rejectPermitDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDRejectPut(researchProjectID: number, rejectPermitDto?: RejectPermitDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectsResearchProjectIDRejectPut(researchProjectID: number, rejectPermitDto?: RejectPermitDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectsResearchProjectIDRejectPut(researchProjectID: number, rejectPermitDto?: RejectPermitDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectsResearchProjectIDRejectPut(researchProjectID: number, rejectPermitDto?: RejectPermitDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDRejectPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/reject`,
            rejectPermitDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDRenewPost(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDRenewPost(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDRenewPost(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDRenewPost(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDRenewPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/renew`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDRenewalsExistGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public researchProjectsResearchProjectIDRenewalsExistGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public researchProjectsResearchProjectIDRenewalsExistGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public researchProjectsResearchProjectIDRenewalsExistGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDRenewalsExistGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<boolean>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/renewalsExist`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDRenewedFromResearchProjectGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectRenewedFromDto>;
    public researchProjectsResearchProjectIDRenewedFromResearchProjectGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectRenewedFromDto>>;
    public researchProjectsResearchProjectIDRenewedFromResearchProjectGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectRenewedFromDto>>;
    public researchProjectsResearchProjectIDRenewedFromResearchProjectGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDRenewedFromResearchProjectGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectRenewedFromDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/renewedFromResearchProject`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param additionalInformationRequestedCreateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDSendBackToReviewerPut(researchProjectID: number, additionalInformationRequestedCreateDto?: AdditionalInformationRequestedCreateDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectInternalFieldsDto>;
    public researchProjectsResearchProjectIDSendBackToReviewerPut(researchProjectID: number, additionalInformationRequestedCreateDto?: AdditionalInformationRequestedCreateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDSendBackToReviewerPut(researchProjectID: number, additionalInformationRequestedCreateDto?: AdditionalInformationRequestedCreateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDSendBackToReviewerPut(researchProjectID: number, additionalInformationRequestedCreateDto?: AdditionalInformationRequestedCreateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDSendBackToReviewerPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectInternalFieldsDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/sendBackToReviewer`,
            additionalInformationRequestedCreateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDSuccessGet(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectSuccessDto>;
    public researchProjectsResearchProjectIDSuccessGet(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectSuccessDto>>;
    public researchProjectsResearchProjectIDSuccessGet(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectSuccessDto>>;
    public researchProjectsResearchProjectIDSuccessGet(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDSuccessGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ResearchProjectSuccessDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/success`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectAdditionalDocumentationUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut(researchProjectID: number, researchProjectAdditionalDocumentationUpsertDto?: ResearchProjectAdditionalDocumentationUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut(researchProjectID: number, researchProjectAdditionalDocumentationUpsertDto?: ResearchProjectAdditionalDocumentationUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut(researchProjectID: number, researchProjectAdditionalDocumentationUpsertDto?: ResearchProjectAdditionalDocumentationUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut(researchProjectID: number, researchProjectAdditionalDocumentationUpsertDto?: ResearchProjectAdditionalDocumentationUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateAdditionalDocumentation`,
            researchProjectAdditionalDocumentationUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectDataCollectionUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateDataCollectionPut(researchProjectID: number, researchProjectDataCollectionUpsertDto?: ResearchProjectDataCollectionUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdateDataCollectionPut(researchProjectID: number, researchProjectDataCollectionUpsertDto?: ResearchProjectDataCollectionUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateDataCollectionPut(researchProjectID: number, researchProjectDataCollectionUpsertDto?: ResearchProjectDataCollectionUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateDataCollectionPut(researchProjectID: number, researchProjectDataCollectionUpsertDto?: ResearchProjectDataCollectionUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateDataCollectionPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateDataCollection`,
            researchProjectDataCollectionUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectDateUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateDatesPut(researchProjectID: number, researchProjectDateUpsertDto?: ResearchProjectDateUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdateDatesPut(researchProjectID: number, researchProjectDateUpsertDto?: ResearchProjectDateUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateDatesPut(researchProjectID: number, researchProjectDateUpsertDto?: ResearchProjectDateUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateDatesPut(researchProjectID: number, researchProjectDateUpsertDto?: ResearchProjectDateUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateDatesPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateDates`,
            researchProjectDateUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectInformationUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateInformationPut(researchProjectID: number, researchProjectInformationUpsertDto?: ResearchProjectInformationUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdateInformationPut(researchProjectID: number, researchProjectInformationUpsertDto?: ResearchProjectInformationUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateInformationPut(researchProjectID: number, researchProjectInformationUpsertDto?: ResearchProjectInformationUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateInformationPut(researchProjectID: number, researchProjectInformationUpsertDto?: ResearchProjectInformationUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateInformationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateInformation`,
            researchProjectInformationUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectInternalFieldsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateInternalFieldsPut(researchProjectID: number, researchProjectInternalFieldsDto?: ResearchProjectInternalFieldsDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectInternalFieldsDto>;
    public researchProjectsResearchProjectIDUpdateInternalFieldsPut(researchProjectID: number, researchProjectInternalFieldsDto?: ResearchProjectInternalFieldsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDUpdateInternalFieldsPut(researchProjectID: number, researchProjectInternalFieldsDto?: ResearchProjectInternalFieldsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectInternalFieldsDto>>;
    public researchProjectsResearchProjectIDUpdateInternalFieldsPut(researchProjectID: number, researchProjectInternalFieldsDto?: ResearchProjectInternalFieldsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateInternalFieldsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectInternalFieldsDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateInternalFields`,
            researchProjectInternalFieldsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectUpdatePersonInChargeOfFieldWorkFlagDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut(researchProjectID: number, researchProjectUpdatePersonInChargeOfFieldWorkFlagDto?: ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut(researchProjectID: number, researchProjectUpdatePersonInChargeOfFieldWorkFlagDto?: ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut(researchProjectID: number, researchProjectUpdatePersonInChargeOfFieldWorkFlagDto?: ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut(researchProjectID: number, researchProjectUpdatePersonInChargeOfFieldWorkFlagDto?: ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updatePersonInChargeOfFieldWorkFlag`,
            researchProjectUpdatePersonInChargeOfFieldWorkFlagDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectResearchTeamUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateResearchTeamFlagsPut(researchProjectID: number, researchProjectResearchTeamUpsertDto?: ResearchProjectResearchTeamUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdateResearchTeamFlagsPut(researchProjectID: number, researchProjectResearchTeamUpsertDto?: ResearchProjectResearchTeamUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateResearchTeamFlagsPut(researchProjectID: number, researchProjectResearchTeamUpsertDto?: ResearchProjectResearchTeamUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateResearchTeamFlagsPut(researchProjectID: number, researchProjectResearchTeamUpsertDto?: ResearchProjectResearchTeamUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateResearchTeamFlagsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateResearchTeamFlags`,
            researchProjectResearchTeamUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param researchProjectUpdateUserIsThePrincipalInvestigatorDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut(researchProjectID: number, researchProjectUpdateUserIsThePrincipalInvestigatorDto?: ResearchProjectUpdateUserIsThePrincipalInvestigatorDto, observe?: 'body', reportProgress?: boolean): Observable<ResearchProjectDto>;
    public researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut(researchProjectID: number, researchProjectUpdateUserIsThePrincipalInvestigatorDto?: ResearchProjectUpdateUserIsThePrincipalInvestigatorDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut(researchProjectID: number, researchProjectUpdateUserIsThePrincipalInvestigatorDto?: ResearchProjectUpdateUserIsThePrincipalInvestigatorDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResearchProjectDto>>;
    public researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut(researchProjectID: number, researchProjectUpdateUserIsThePrincipalInvestigatorDto?: ResearchProjectUpdateUserIsThePrincipalInvestigatorDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ResearchProjectDto>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/updateUserIsThePrincipalInvestigator`,
            researchProjectUpdateUserIsThePrincipalInvestigatorDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param researchProjectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public researchProjectsResearchProjectIDWithdrawPut(researchProjectID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public researchProjectsResearchProjectIDWithdrawPut(researchProjectID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public researchProjectsResearchProjectIDWithdrawPut(researchProjectID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public researchProjectsResearchProjectIDWithdrawPut(researchProjectID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (researchProjectID === null || researchProjectID === undefined) {
            throw new Error('Required parameter researchProjectID was null or undefined when calling researchProjectsResearchProjectIDWithdrawPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/researchProjects/${encodeURIComponent(String(researchProjectID))}/withdraw`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
