import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {BasicFormComponent, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {UserService} from 'src/app/shared/generated/api/user.service';
import {UserUpsertDto} from 'src/app/shared/generated/model/user-upsert-dto';
import {BooleanIdAndDisplayNameArray} from 'src/app/shared/models/boolean.util';
import {RoleIdAndDisplayNameArray} from 'src/app/shared/models/enums/role.enum.util';
import {AlertService} from 'src/app/shared/services/alert.service';
import {DistrictService} from "../../../../shared/generated/api/district.service";
import {
    ResearchProjectCategoryTypeIdAndDisplayNameArray
} from "../../../../shared/models/enums/research-project-category.enum.util";
import {RoleEnum} from "../../../../shared/generated/enum/role-enum";

@Component({
    selector: 'castateparksscp-user-form',
    templateUrl: '../../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../../basic/shared/basic-form/basic-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent extends BasicFormComponent implements OnInit, OnChanges {
    upsertDto: UserUpsertDto;

    roleOptions: Array<{ text: string, value: number }> = RoleIdAndDisplayNameArray;
    researchCategoryOptions: Array<{ text: string, value: number }> = ResearchProjectCategoryTypeIdAndDisplayNameArray.map(x => {
        return Object.assign(x, {content: x.text})
    });
    booleanOptions: Array<{ text: string, value: boolean }> = BooleanIdAndDisplayNameArray;
    @Input() districtOptions: Array<{ text: string, value: number }> = [];
    formPage: FormPage = {
        dtoName: 'user',
        elements: [
            {
                class: 'g-col-12',
                name: 'FirstName',
                label: 'First Name',
                required: true,
                fieldDefinitionType: 'FirstName',
                type: 'text',
            },
            {
                class: 'g-col-12',
                name: 'LastName',
                label: 'Last Name',
                required: true,
                fieldDefinitionType: 'LastName',
                type: 'text',
            },
            {
                class: 'g-col-12',
                name: 'PhoneNumber',
                label: 'Phone Number',
                required: false,
                fieldDefinitionType: 'PhoneNumber',
                type: 'phoneNumber',
            },
            {
                class: 'g-col-12',
                name: 'Email',
                label: 'Email',
                required: true,
                fieldDefinitionType: 'Email',
                type: 'text',
            },
            {
                class: 'g-col-12',
                name: 'RoleID',
                label: 'Role',
                required: true,
                selectDisplayFieldName: 'RoleDisplayName',
                type: 'select',
                selectOptions: this.roleOptions,
                onChange: () => {
                    if (RoleEnum.TechnicalReviewer != this.upsertDto['RoleID']) this.upsertDto['ResearchProjectCategoryTypeIDs'] = null;
                }
            },
            {
                class: 'g-col-12',
                name: 'ResearchProjectCategoryTypeIDs',
                label: 'Expertise',
                selectDisplayFieldName: 'ResearchProjectCategoryTypeDisplayName',
                type: 'multiselect',
                selectOptions: this.researchCategoryOptions,
                disableCondition: () => RoleEnum.TechnicalReviewer != this.upsertDto['RoleID']
            },
            {
                class: 'g-col-12',
                name: 'DistrictID',
                label: 'District',
                selectDisplayFieldName: 'DistrictDisplayName',
                type: 'select',
                selectOptions: this.districtOptions,
                disableCondition: () => RoleEnum.DistrictStaff != this.upsertDto['RoleID']
            },
        ]
    } as FormPage;

    constructor(public userService: UserService,
                public districtService: DistrictService,
                private cdr: ChangeDetectorRef,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            dto['RoleID'] = dto.Role.RoleID;
            dto['DistrictID'] = dto.DistrictID;
            dto['OrganizationName'] = dto.Company;
            dto['PhoneNumber'] = dto.Phone;
            return dto;
        };
        this.formPage.put = (id, upsertDto) => this.userService.usersUserIDPut(id, upsertDto);
    }

    override ngOnInit() {
        this.districtService.districtsGet().subscribe(districts => {
            this.districtOptions = districts.map(d => {
                return {text: d.Name, value: d.DistrictID};
            });

            let element = this.formPage.elements.find(e => e.name == 'DistrictID');
            element.selectOptions = this.districtOptions;
            this.cdr.markForCheck();
        });
        super.ngOnInit();
    }
}
