import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ContactService } from './api/contact.service';
import { CountryService } from './api/country.service';
import { CustomPageService } from './api/custom-page.service';
import { CustomRichTextService } from './api/custom-rich-text.service';
import { DistrictService } from './api/district.service';
import { FieldDefinitionService } from './api/field-definition.service';
import { ImpersonationService } from './api/impersonation.service';
import { MenuItemService } from './api/menu-item.service';
import { ParkService } from './api/park.service';
import { PermitWorkflowChangeService } from './api/permit-workflow-change.service';
import { ProjectService } from './api/project.service';
import { ProjectFileService } from './api/project-file.service';
import { ResearchProjectService } from './api/research-project.service';
import { ResearchProjectAreaParkService } from './api/research-project-area-park.service';
import { ResearchProjectContactService } from './api/research-project-contact.service';
import { ResearchProjectFieldInvestigationOccurrenceService } from './api/research-project-field-investigation-occurrence.service';
import { ResearchProjectFileService } from './api/research-project-file.service';
import { ResearchProjectPermitService } from './api/research-project-permit.service';
import { ResearchProjectSpecialConditionService } from './api/research-project-special-condition.service';
import { ResearchProjectSpecimenItemService } from './api/research-project-specimen-item.service';
import { ResearchProjectTagService } from './api/research-project-tag.service';
import { ResourceCategoryTagService } from './api/resource-category-tag.service';
import { RoleService } from './api/role.service';
import { SectorService } from './api/sector.service';
import { SpecialConditionService } from './api/special-condition.service';
import { StandardConditionService } from './api/standard-condition.service';
import { StateService } from './api/state.service';
import { SystemInfoService } from './api/system-info.service';
import { TaxonService } from './api/taxon.service';
import { UserService } from './api/user.service';
import { UserClaimsService } from './api/user-claims.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ContactService,
    CountryService,
    CustomPageService,
    CustomRichTextService,
    DistrictService,
    FieldDefinitionService,
    ImpersonationService,
    MenuItemService,
    ParkService,
    PermitWorkflowChangeService,
    ProjectService,
    ProjectFileService,
    ResearchProjectService,
    ResearchProjectAreaParkService,
    ResearchProjectContactService,
    ResearchProjectFieldInvestigationOccurrenceService,
    ResearchProjectFileService,
    ResearchProjectPermitService,
    ResearchProjectSpecialConditionService,
    ResearchProjectSpecimenItemService,
    ResearchProjectTagService,
    ResourceCategoryTagService,
    RoleService,
    SectorService,
    SpecialConditionService,
    StandardConditionService,
    StateService,
    SystemInfoService,
    TaxonService,
    UserService,
    UserClaimsService,
     ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
