<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="projectDtoRaw"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <h2>Additional Documentation</h2>
        <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>
        <h4>Soil Disturbance</h4>
        <castateparksscp-research-project-soil-disturbance-flag-form
            #researchProjectSoilDisturbanceFlagForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (flagChanged)="handleInvolvesSoilDisturbance($event)"
            [errors]="errors">
        </castateparksscp-research-project-soil-disturbance-flag-form>

        <div [hidden]="!researchProjectDto?.SoilDisturbance">
            <br/>
            <castateparksscp-research-project-soil-disturbance-form
                #researchProjectSoilDisturbanceForm
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="researchProjectDto"
                [editMode]="canEditPage"
                [isHideActionButtons]="true"
                [errors]="errors"
                (textAreaCheckboxChange)="handleProposalDeferredField($event)">
            </castateparksscp-research-project-soil-disturbance-form>
            <castateparksscp-research-project-file-list-manager
                [required]="true"
                [uploadFileButtonText]="'Upload files*'"
                [title]="'Upload soil disturbance areas and supporting documents'"
                [description]="'Identify each ground disturbance area on a USGS 7.5-minute topographic map. Supported files are: XXXX, XXXX, etc.'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.SoilDisturbanceAreaSupportingDocumentation"
                [researchProjectID]="researchProjectID"
                [disabled]="!this.canEditPage"
            ></castateparksscp-research-project-file-list-manager>
        </div>

        <h4>Additional Permits</h4>
        <castateparksscp-research-project-additional-permit-flag-form
            #researchProjectAdditionalPermitFlagForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (flagChanged)="handleInvolvesAdditionalPermit($event)"
            [errors]="errors">
        </castateparksscp-research-project-additional-permit-flag-form>

        <div [hidden]="!researchProjectDto?.AdditionalPermits">
            <castateparksscp-research-project-file-list-manager
                [title]="'Upload any existing additional permits and/or supporting documents'"
                [required]="additionalPermitDocumentRequired()"
                [uploadFileButtonText]="additionalPermitDocumentRequired() ? 'Upload files*' : 'Upload files'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.AdditionalPermitSupportingDocumentation"
                [researchProjectID]="researchProjectID"
                [disabled]="!this.canEditPage"
            ></castateparksscp-research-project-file-list-manager>
            <castateparksscp-research-project-additional-permit-form
                #researchProjectAdditionalPermitForm
                [isCDS]="true"
                [dto]="researchProjectDto"
                [editMode]="canEditPage"
                [isHideActionButtons]="true"
                [errors]="errors">
            </castateparksscp-research-project-additional-permit-form>
        </div>

        <h4>Study Supporting Information</h4>
        <castateparksscp-research-project-budget-form
            #researchProjectBudgetForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            [errors]="errors"
            (textAreaCheckboxChange)="handleProposalDeferredField($event)">
        </castateparksscp-research-project-budget-form>
        <castateparksscp-research-project-file-list-manager
            [disabled]="!!budgetForm?.upsertDto?.ProposalIncludesBudget || !this.canEditPage"
            [uploadFileButtonText]="'Upload supporting docs'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.BudgetSupportingDocumentation"
            [researchProjectID]="researchProjectID"
        ></castateparksscp-research-project-file-list-manager>

        <br/>
        <br/>
        <castateparksscp-research-project-literature-cited-form
            #researchProjectLiteratureCitedForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            [errors]="errors"
            (textAreaCheckboxChange)="handleProposalDeferredField($event)">
        </castateparksscp-research-project-literature-cited-form>
        <castateparksscp-research-project-file-list-manager
            [disabled]="!!literatureCitedForm?.upsertDto?.ProposalIncludesLiteratureCited || !this.canEditPage"
            [uploadFileButtonText]="'Upload supporting docs'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.LiteratureCitedSupportingDocumentation"
            [researchProjectID]="researchProjectID"
        ></castateparksscp-research-project-file-list-manager>

        <h4>Study Proposal ({{!!getProposalRequirements()?.length ? 'Required' : 'Optional'}})</h4>
        <cds-text-label>{{getStudyProposalText()}}</cds-text-label>
        <ul cdsList>
            <li cdsListItem *ngFor="let field of getProposalRequirementList()">{{field}}</li>
        </ul>
        <castateparksscp-research-project-file-list-manager
            [uploadFileButtonText]="'Upload study proposal'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.StudyProposal"
            [researchProjectID]="researchProjectID"
            [disabled]="!this.canEditPage"
            [supportedFileTypes]="['.pdf']"
        ></castateparksscp-research-project-file-list-manager>

        <h4>Required Documents</h4>
        <label class="cds--file--label">Upload signed standard condition agreement form
            (<a cdsLink href="/assets/pdfs/standard_conditions_agreement.pdf" target="_blank">Download unsigned form</a>)</label>
        <div class="grid-12">
            <div class="g-col-7">
                <p class="cds--label-description" style="margin-block-end: 0">The Principal Investigator, {{piContact?.FirstName}} {{piContact?.LastName}}, must sign this required document. Please
                    upload the signed copy.</p>
            </div>
        </div>
        <castateparksscp-research-project-file-list-manager
            [required]="true"
            [uploadFileButtonText]="'Upload signed form*'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.StandardConditionsAgreementForm"
            [researchProjectID]="researchProjectID"
            [disabled]="!this.canEditPage"
            [supportedFileTypes]="['.pdf']"
        ></castateparksscp-research-project-file-list-manager>

        <label class="cds--file--label">Upload signed waiver and indemnity agreement form
            (<a cdsLink href="/assets/pdfs/waiver_and_indemnity_agreement.pdf" target="_blank">Download unsigned form</a>)</label>
        <div class="grid-12">
            <div class="g-col-7">
                <p class="cds--label-description" style="margin-block-end: 0">A representative of the organization
                    {{ !!organizationContact?.OrganizationName ? " " + organizationContact?.OrganizationName + " " : "" }}
                    authorized to agree on the indemnification terms must sign this required document. This may or may
                    not be the people on the permit. Please upload the signed document</p>
            </div>
        </div>
        <castateparksscp-research-project-file-list-manager
            [required]="true"
            [uploadFileButtonText]="'Upload signed form*'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.WaiverAndIndemnityAgreementForm"
            [researchProjectID]="researchProjectID"
            [disabled]="!this.canEditPage"
            [supportedFileTypes]="['.pdf']"
        ></castateparksscp-research-project-file-list-manager>

        <br/>
        <br/>
        <castateparksscp-research-project-dpr65a-flag-form
            #researchProjectDpr65aFlagForm
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (flagChanged)="handleInvolvesDpr65A($event)"
            [errors]="errors">
        </castateparksscp-research-project-dpr65a-flag-form>
        <ng-container *ngIf="researchProjectDto?.OptionalInsuranceAddendumDpr65A">
            <castateparksscp-research-project-file-list-manager
                [title]="'Required Insurance Addendum*'"
                [titleLinkRoute]="'/assets/pdfs/DPR65A.pdf'"
                [required]="true"
                [uploadFileButtonText]="'Upload signed form*'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.OptionalInsuranceAddendumDpr65A"
                [researchProjectID]="researchProjectID"
                [disabled]="!this.canEditPage"
                [supportedFileTypes]="['.pdf']"
            ></castateparksscp-research-project-file-list-manager>
        </ng-container>
        <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right;">
            <button cdsButton size="xl" (click)="saveAndContinue()">Save and Continue
                <castateparksscp-right-arrow></castateparksscp-right-arrow>
            </button>
        </div>
    </div>
</div>
<castateparksscp-basic-modal *ngIf="modalConfig" [open]="modalOpen" [config]="modalConfig" (close)="modalOpen = false"></castateparksscp-basic-modal>

