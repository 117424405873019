import {Observable, race, ReplaySubject} from 'rxjs';
import {filter, first, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {RoleEnum} from '../shared/generated/enum/role-enum';
import {AlertService} from '../shared/services/alert.service';
import {Alert} from '../shared/models/alert';
import {AlertContext} from '../shared/models/enums/alert-context.enum';
import {ImpersonationService} from '../shared/generated/api/impersonation.service';
import {Inject, Injectable} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionStatus,
    InteractionType,
    PopupRequest,
    RedirectRequest
} from '@azure/msal-browser';
import {b2cPolicies} from '../auth.config';
import {UserClaimsService} from '../shared/generated/api/user-claims.service';
import {UserDto} from '../shared/generated/model/user-dto';
import {PermissionEnum} from '../shared/generated/enum/permission-enum';
import {RightsEnum} from '../shared/models/enums/rights.enum';
import {FlagEnum} from '../shared/generated/enum/flag-enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUser: UserDto;
  private claimsUser: any;

  private _currentUserSetSubject = new ReplaySubject<UserDto>(1);
  public currentUserSetObservable = this._currentUserSetSubject.asObservable();

  constructor(private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userClaimsService: UserClaimsService,
    private impersonationService: ImpersonationService,
    private alertService: AlertService) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.claimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
        this.postUser();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();

        var newClaimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
        if (newClaimsUser && newClaimsUser["tfp"] == b2cPolicies.names.editProfile) {
          this.claimsUser = newClaimsUser;
          this.postUser();
        } else if (newClaimsUser && (!this.claimsUser || newClaimsUser["sub"] != this.claimsUser["sub"])) {
          this.claimsUser = newClaimsUser;
          this.getUser(this.claimsUser);
        }
      });

  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  public refreshUser() {
    this.getUser(this.claimsUser);
  }

  private getUser(claims: any) {
    var globalID = claims["sub"];

    this.userClaimsService.userClaimsGlobalIDGet(globalID).subscribe(
      result => { this.updateUser(result) },
      () => { this.onGetUserError() }
    );
  }

  private postUser() {
    this.userClaimsService.userClaimsPost().subscribe(
      result => { this.updateUser(result) },
      () => { this.onGetUserError() }
    );
  }

  private updateUser(user: UserDto) {
    this.currentUser = user;
    this._currentUserSetSubject.next(this.currentUser);
  }

  private onGetUserError() {
    this.alertService.pushAlert(new Alert("There was an error logging into the application.", AlertContext.Danger));
    this.router.navigate(['/']);
  }

  public refreshUserInfo(user: UserDto) {
    this.updateUser(user);
  }

  public isAuthenticated(): boolean {
    return this.claimsUser != null;
  }

  public isInternalUser(): boolean {
      return this.currentUser.Role.RoleID != RoleEnum.Public;
  }

  public handleUnauthorized(): void {
    this.forcedLogout();
  }

  public forcedLogout() {
    if (!this.isCurrentUserBeingImpersonated(this.currentUser)) {
      sessionStorage["authRedirectUrl"] = window.location.href;
    }
    this.logout();
  }

  public login(userFlowRequest?: RedirectRequest | PopupRequest, successRequest?: Observable<any>) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            if (!!successRequest) successRequest.subscribe();
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  public logout() {
    if (this.isCurrentUserBeingImpersonated(this.currentUser)) {
      this.impersonationService.impersonateStopImpersonationPost().subscribe(response => {
        this.refreshUserInfo(response);
        this.router.navigateByUrl("/").then(x => {
          this.alertService.pushAlert(new Alert(`Finished impersonating`, AlertContext.Success));
        });
      });
    } else {
      this.authService.logout();
    }
  }

  editProfile() {
    let editProfileFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.editProfile.authority,
    };

    this.login(editProfileFlowRequest);
  }
  public isCurrentUserBeingImpersonated(user: UserDto): boolean {
    if (user) {
      var globalID = this.claimsUser["sub"];
      return globalID != user.UserGuid;
    }
    return false;
  }

  public getAuthRedirectUrl() {
    return sessionStorage["authRedirectUrl"];
  }

  public setAuthRedirectUrl(url: string) {
    sessionStorage["authRedirectUrl"] = url;
  }

  public clearAuthRedirectUrl() {
    this.setAuthRedirectUrl("");
  }

  public isUserUnassigned(user: UserDto): boolean {
    const role = user && user.Role
      ? user.Role.RoleID
      : null;
    return role === RoleEnum.Public && user.IsActive;
  }

  public isUserRoleDisabled(user: UserDto): boolean {
    const role = user && user.Role
      ? user.Role.RoleID
      : null;
    return role === RoleEnum.Public && !user.IsActive;
  }

  public isCurrentUserNullOrUndefined(): boolean {
    return !this.currentUser;
  }

  public doesCurrentUserHaveOneOfTheseRoles(roleIDs: Array<number>): boolean {
    if (roleIDs.length === 0) {
      return false;
    }
    const roleID = this.currentUser && this.currentUser.Role
      ? this.currentUser.Role.RoleID
      : null;
    return roleIDs.includes(roleID);
  }

  public getCurrentUser(): Observable<UserDto> {
    return race(
      new Observable(subscriber => {
        if (this.currentUser) {
          subscriber.next(this.currentUser);
          subscriber.complete();
        }
      }),
      this.currentUserSetObservable.pipe(first())
    );
  }

  public getCurrentUserID(): Observable<number> {
    return race(
      new Observable<number>(subscriber => {
        if (this.currentUser) {
          subscriber.next(this.currentUser.UserID);
          subscriber.complete();
        }
      }),
      this.currentUserSetObservable.pipe(first(), map(
        (user) => user.UserID
      ))
    );
  }

  public hasPermission(user: UserDto, permission: PermissionEnum, rights: RightsEnum): boolean {
    if (!user) return false;

    const permissionName = PermissionEnum[permission];

    const hasPermission = (user && user.Rights && user.Rights[permissionName])
      ? user.Rights[permissionName][rights]
      : false;

    return hasPermission;
  }

  public hasFlag(user: UserDto, flag: FlagEnum): boolean {
    const flagName = FlagEnum[flag];

    const hasFlag = (user && user.Flags)
      ? user.Flags[flagName]
      : false;

    return hasFlag;
  }
}
