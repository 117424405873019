<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="researchProjectDto"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()"
        >
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <h2>Research Team</h2>
        <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>
        <h4>Organization</h4>
        <castateparksscp-contact-form-organization
            #contactFormOrganization
            [errors]="organizationErrors"
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="organizationContactDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="saveOrganization($event)"
            (cancelEditModeChange)="cancelEditOrganization()"></castateparksscp-contact-form-organization>

        <h4>Principal Investigator</h4>
        <castateparksscp-research-team-user-is-principal-investigator-form
            #userIsPrincipalInvestigatorForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="savePersonInChargeOfFieldWork($event)"
            (cancelEditModeChange)="cancelEditPersonInChargeOfFieldWork()"
            (userIsThePrincipalInvestigator)="userIsThePrincipalInvestigatorChanged($event)">
        </castateparksscp-research-team-user-is-principal-investigator-form>
        <br>

        <castateparksscp-contact-form-principal-investigator
            #contactFormPrincipalInvestigator
            [isLoading]="isLoading"
            [isPrincipalInvestigator]="!!researchProjectDto?.UserIsThePrincipalInvestigator"
            [errors]="principalInvestigatorErrors"
            [isCDS]="true"
            [dto]="principalInvestigatorContactDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="savePrincipalInvestigator($event)"
            (cancelEditModeChange)="cancelEditPrincipalInvestigator()"></castateparksscp-contact-form-principal-investigator>

        <castateparksscp-research-project-file-list-manager
            [required]="true"
            [uploadFileButtonText]="'Upload Resume or CV*'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.PrincipalInvestigatorResume"
            [researchProjectID]="researchProjectID"
            [useCDS]="true"
            [disabled]="!canEditPage"
            [supportedFileTypes]="['.pdf']"
        ></castateparksscp-research-project-file-list-manager>

        <h4>Person in Direct Charge of Field Work</h4>

        <castateparksscp-research-team-person-charge-of-field-work-flag-form
            #researchTeamPersonChargeOfFieldWorkFlagForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="savePersonInChargeOfFieldWork($event)"
            (cancelEditModeChange)="cancelEditPersonInChargeOfFieldWork()"
            (userIsThePersonInChargeOfFieldWork)="handleUserIsThePersonInChargeOfFieldWorkChange($event)"
            (principalInvestigatorIsThePersonInChargeOfFieldWork)="principalInvestigatorIsThePersonInChargeOfFieldWorkChanged($event)"
        >
        </castateparksscp-research-team-person-charge-of-field-work-flag-form>
        <br>

        <div [hidden]="isHidePersonInChargeOfFieldWorkItems()">
            <castateparksscp-contact-form-person-in-charge-of-field-work
                #contactFormPersonInChargeOfFieldWork
                [errors]="fieldWorkErrors"
                [isLoading]="isLoading"
                [isCDS]="true"
                [dto]="personInChargeOfFieldWorkContactDto"
                [editMode]="canEditPage"
                [isHideActionButtons]="true"
                (formSubmitted)="savePersonInChargeOfFieldWork($event)"
                (cancelEditModeChange)="cancelEditPersonInChargeOfFieldWork()"></castateparksscp-contact-form-person-in-charge-of-field-work>

            <castateparksscp-research-project-file-list-manager
                [required]="true"
                [uploadFileButtonText]="'Upload Resume or CV*'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.PersonInChargeOfFieldWorkResume"
                [researchProjectID]="researchProjectID"
                [useCDS]="true"
                [disabled]="!canEditPage"
                [supportedFileTypes]="['.pdf']"
            ></castateparksscp-research-project-file-list-manager>
        </div>

        <castateparksscp-research-team-additional-participant-list
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        >
        </castateparksscp-research-team-additional-participant-list>

        <castateparksscp-research-project-dpr65b-flag-form
            #researchProjectDpr65bFlagForm
            [ID]="researchProjectID"
            [isCDS]="true"
            [dto]="researchProjectDto"
            [editMode]="canEditPage"
            [isHideActionButtons]="true">
        </castateparksscp-research-project-dpr65b-flag-form>
        <ng-container *ngIf="!dpr65bFlagForm?.upsertDto?.OptionalLiabilityWaiverAddendumDpr65B">
            <castateparksscp-research-project-file-list-manager
                [titleLinkRoute]="'/assets/pdfs/DPR65B.pdf'"
                [titleLinkLabel]="'Download unsigned form'"
                [title]="'Required Liability Waiver Addendum'"
                [uploadFileButtonText]="'Upload signed form*'"
                [required]="true"
                [description]="'This document must be signed by ALL participants involved in research activities, so plan accordingly.'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.OptionalLiabilityWaiverAddendumDpr65B"
                [researchProjectID]="researchProjectID"
                [disabled]="!canEditPage"
                [supportedFileTypes]="['.pdf']"
            ></castateparksscp-research-project-file-list-manager>
        </ng-container>

        <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right;">
            <button cdsButton size="xl" (click)="saveAndContinue()">Save and Continue
                <castateparksscp-right-arrow></castateparksscp-right-arrow>
            </button>
        </div>
    </div>
</div>
<castateparksscp-basic-modal *ngIf="modalConfig" [open]="modalOpen" [config]="modalConfig" (close)="modalOpen = false"></castateparksscp-basic-modal>
<castateparksscp-basic-modal *ngIf="secondModalConfig" [open]="secondModalOpen" [config]="secondModalConfig" (close)="secondModalOpen = false"></castateparksscp-basic-modal>
