import {Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {AlertService} from 'src/app/shared/services/alert.service';
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {CurrentResearchProjectService} from "../../../services/current-research-project.service";
import {
    ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto
} from "../../../shared/generated/model/research-project-update-person-in-charge-of-field-work-flag-dto";

@Component({
    selector: 'castateparksscp-research-team-person-charge-of-field-work-flag-form',
    templateUrl: '../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ResearchTeamPersonChargeOfFieldWorkFlagForm extends BasicFormComponent implements OnInit, OnChanges {
    @Output() userIsThePersonInChargeOfFieldWork = new EventEmitter<any>();
    @Output() principalInvestigatorIsThePersonInChargeOfFieldWork = new EventEmitter<any>();

    upsertDto: ResearchProjectUpdatePersonInChargeOfFieldWorkFlagDto;

    personInChargeIsSameAsPrincipalInvestigatorRadioOptions: Array<{ text: string, value: any }> = [
        {text: "Same as Principal Investigator", value: true},
        {text: "Different from Principal Investigator", value: false}
    ];

    userInChargeOfDirectFieldWorkRadioOptions: Array<{ text: string, value: any }> = [
        {text: "I am the person in direct charge of field work", value: true},
        {text: "I am not the person in direct charge of field work", value: false}
    ];

    formPage: FormPage = {
        dtoName: 'researchProject',
        elements: [
            {
                class: 'g-col-7',
                name: 'PersonInChargeOfFieldWorkIsPrincipalInvestigator',
                label: '',
                required: false,
                type: 'radio',
                radioOptions: this.personInChargeIsSameAsPrincipalInvestigatorRadioOptions,
                onChange: this.principalInvestigatorIsThePersonInChargeOfFieldWorkChanged.bind(this),

            },
            {
                class: 'g-col-7',
                name: 'UserIsThePersonInChargeOfFieldWork',
                label: '',
                required: false,
                type: 'radio',
                radioOptions: this.userInChargeOfDirectFieldWorkRadioOptions,
                onChange: this.userIsThePersonInChargeOfFieldWorkChanged.bind(this),
                hidden: this.dto?.PersonInChargeOfFieldWorkIsPrincipalInvestigator

            },
        ]
    } as FormPage;

    constructor(public currentResearchProjectService: CurrentResearchProjectService,
                public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
             return {
                 UserIsThePersonInChargeOfFieldWork: dto?.UserIsThePersonInChargeOfFieldWork,
                 PersonInChargeOfFieldWorkIsPrincipalInvestigator: dto?.PersonInChargeOfFieldWorkIsPrincipalInvestigator
             }
        };
        this.formPage.post = (upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut(upsertDto);
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdatePersonInChargeOfFieldWorkFlagPut(id, upsertDto);
    }

    public userIsThePersonInChargeOfFieldWorkChanged(value: boolean): void {
        this.userIsThePersonInChargeOfFieldWork.emit(value);
    }
    public principalInvestigatorIsThePersonInChargeOfFieldWorkChanged(value: boolean): void {
        this.principalInvestigatorIsThePersonInChargeOfFieldWork.emit(value);
        if (value) {
            this.upsertDto['UserIsThePersonInChargeOfFieldWork'] = this.dto.UserIsThePrincipalInvestigator;
        }
    }

    refreshFormPageElements() {
        this.formPage.elements =  [
            {
                class: 'g-col-7',
                name: 'PersonInChargeOfFieldWorkIsPrincipalInvestigator',
                label: '',
                required: false,
                type: 'radio',
                radioOptions: this.personInChargeIsSameAsPrincipalInvestigatorRadioOptions,
                onChange: this.principalInvestigatorIsThePersonInChargeOfFieldWorkChanged.bind(this),

            },
            {
                class: 'g-col-7',
                name: 'UserIsThePersonInChargeOfFieldWork',
                label: '',
                required: false,
                type: 'radio',
                radioOptions: this.userInChargeOfDirectFieldWorkRadioOptions,
                onChange: this.userIsThePersonInChargeOfFieldWorkChanged.bind(this),
                hidden: this.dto?.PersonInChargeOfFieldWorkIsPrincipalInvestigator,
            },
        ] as FormElement[];
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormPageElements();
    }
}
