import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BasicListComponent, ListPage} from "../../basic/basic-list/basic-list.component";
import {PermissionEnum} from "../../../shared/generated/enum/permission-enum";
import {AuthenticationService} from "../../../services/authentication.service";
import {DateColumnCreatorService} from "../../../shared/services/date-column-creator/date-column-creator.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../shared/services/alert.service";
import {MatDialog} from "@angular/material/dialog";
import {
    InLineEditButtonsRendererComponent
} from "../../../shared/components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component";
import {GetRowIdFunc, GetRowIdParams} from "ag-grid-community";
import {RequiredHeaderComponent} from "../../../shared/components/ag-grid/required-header/required-header.component";
import {
    ResearchProjectSpecialConditionService
} from "../../../shared/generated/api/research-project-special-condition.service";
import {HtmlRendererComponent} from "../../../shared/components/ag-grid/html-renderer/html-renderer.component";
import {
    ResearchProjectSpecialConditionSimpleDto
} from "../../../shared/generated/model/research-project-special-condition-simple-dto";
import {RightsEnum} from "../../../shared/models/enums/rights.enum";
import {
    OrderEditButtonRendererComponent
} from "../../../shared/components/ag-grid/order-edit-button-renderer/order-edit-button-renderer.component";
import {Alert} from "../../../shared/models/alert";
import {AlertContext} from "../../../shared/models/enums/alert-context.enum";
import {BasicConfigurableModalComponent} from "../../basic/basic-configurable-modal/basic-configurable-modal.component";
import {FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {
    ResearchProjectSpecialConditionUpsertDto
} from "../../../shared/generated/model/research-project-special-condition-upsert-dto";
import {SpecialConditionService} from "../../../shared/generated/api/special-condition.service";
import {BasicModalConfiguration} from "../../basic/basic-modal/basic-modal.component";
import {finalize} from "rxjs";

@Component({
    selector: 'castateparksscp-research-project-special-conditions-grid',
    templateUrl: '../../basic/basic-list/basic-list.component.html',
})
export class ResearchProjectSpecialConditionsGridComponent extends BasicListComponent implements OnInit {

    @Input() researchProjectID: number;
    isH4Title: boolean = true;

    listPage: ListPage = {
        permission: PermissionEnum.InternalReviewRights,
        pageTitle: 'HQ Special Conditions',
        createButtonLabel: 'Special Condition',
        downloadFileName: 'researchProjectSpecialConditions',
        isShowAddButton: true,
        addButtonCdsButton: 'secondary',
        iSShowTitle: true,
        defaultColDef: {
            sortable: true,
            filter: false,
            resizable: true,
            floatingFilter: false,
            suppressMenu: true,
        },
        isHideDownload: true,
        isShowCustomButton: true,
        customButtonLabel: "Import Special Conditions",
        customButtonCdsButton: 'tertiary'
    } as ListPage;
    hasListViewPadding = false;

    useInGridEdit: boolean = false;
    public idField: string = 'ResearchProjectSpecialConditionID';
    public startEditingColKey: string = 'ResearchProjectSpecialConditionID';

    constructor(
        public authenticationService: AuthenticationService,
        public dateColumnCreator: DateColumnCreatorService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        public alertService: AlertService,
        public dialog: MatDialog,
        public researchProjectSpecialConditionService: ResearchProjectSpecialConditionService,
        public specialConditionService: SpecialConditionService
    ) {
        super(authenticationService, dateColumnCreator, cdr, router, alertService, dialog);
        this.listPage.dtosGetter = () => this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectIDGet(this.researchProjectID);
        this.listPage.columnDefs = [
            {
                cellRendererSelector: (params) => {
                    return {
                        component: InLineEditButtonsRendererComponent,
                        params: {
                            dtos: this.dtos,
                            save: this.save.bind(this),
                            cancel: this.cancel.bind(this),
                            delete: this.delete.bind(this),
                            edit: this.edit.bind(this),
                            editRowIndex: this.getThisRowIndex.bind(this),
                            editableCallback: this.getCanEdit.bind(this),

                        },
                    };
                },
                field: "ResearchProjectSpecialConditionID",
                width: 100,
                pinned: 'left',
                filterable: false,
                headerName: "",
                editable: false,
            },
            {
                field: "Content",
                headerName: "Condition",
                cellRendererSelector: (params) => {
                    return {
                        component: HtmlRendererComponent,
                        params: params
                    }
                },
                flex: 1,
                editable: false,
                cellEditorPopup: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {maxLength: 1000},
                wrapText: true,
                autoHeight: true,
            },
            {
                field: "Number",
                headerName: "Number",
                suppressNavigable: true,
                width: 100,
                editable: false
            },
        ]
    }

    public getRowId: GetRowIdFunc = (params: GetRowIdParams<ResearchProjectSpecialConditionSimpleDto> | any) => params.data.ResearchProjectSpecialConditionID;

    public putAction = (id: number, dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectSpecialConditionIDPut(id, dto);
    }

    public postAction = (dto: any) => {
        dto.researchProjectID = this.researchProjectID;
        return this.researchProjectSpecialConditionService.researchProjectSpecialConditionsPost(dto);
    }

    public deleteAction = (id: number) => {
        return this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectSpecialConditionIDDelete(id);
    }

    ngOnInit() {
        super.ngOnInit();
        this.gridComponents['agColumnHeader'] = RequiredHeaderComponent;
        this.specialConditionService.specialConditionsGet()
            .subscribe((conditions) => {
                let specialConditionSelectData = conditions.map((condition) => {
                    return {content: condition.Content.slice(0, 177) + '...', value: condition.Content}
                });
                this.listPage.modalConfig = {
                    size: "md",
                    modalHeaderTitle: 'Import Special Conditions',
                    contentTitle: null,
                    content: null,
                    primaryButtonText: 'Submit',
                    secondaryButtonText: 'Cancel',
                    secondaryButtonClick: () => this.modalOpen = false,
                    formPage: {
                        dtoName: 'special conditions',
                        elements: [
                            {
                                class: 'g-col-12',
                                name: 'ContentMultipleSelection',
                                label: 'Conditions',
                                selectDisplayFieldName: 'label',
                                type: 'filterSelect',
                                listItems: specialConditionSelectData,
                                listItemValue: null,
                                displayFilterSelectedItems: true,
                                helperText: 'Select from existing special condition...',
                                required: true
                            },
                        ],
                        createUpsertDto: (dto) => {
                            return {
                                ResearchProjectID: this.researchProjectID,
                                ContentMultipleSelection: []
                            } as ResearchProjectSpecialConditionUpsertDto
                        },
                        post: (dto) => this.researchProjectSpecialConditionService.researchProjectSpecialConditionsMultiplePost(dto).pipe(finalize(() => {
                            this.refreshData();
                            this.editEvent.emit()
                        }))
                    } as unknown as FormPage,
                } as BasicModalConfiguration;
            });
    }

    override doActionAfterSettingCurrentUser() {
        if (this.authenticationService.hasPermission(this.currentUser,
            PermissionEnum.InternalReviewRights,
            RightsEnum.Update)) {
            this.listPage.columnDefs.push({
                cellRendererSelector: (params) => {
                    return {
                        component: OrderEditButtonRendererComponent,
                        params: {
                            dtos: this.dtos,
                            clickMove: this.clickMove.bind(this),
                            editableCallback: this.getCanEdit.bind(this),
                        },
                    };
                },
                width: 100,
                suppressNavigable: true,
                editable: false,
            });
            this.grid?.api?.setGridOption("columnDefs", this.listPage.columnDefs);
        }
    }

    clickMove($event, isUp = false) {
        var number = $event.rowData.Number;
        var request = isUp ? this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectIDIncrementConditionNumberPut(this.researchProjectID, number)
            : this.researchProjectSpecialConditionService.researchProjectSpecialConditionsResearchProjectIDDecrementConditionNumberPut(this.researchProjectID, number);
        request.subscribe({
            next: () => {
            this.alertService.pushAlert(new Alert(`Special Condition Number was successfully updated.`, AlertContext.Success, true));
            this.refreshData();
            }, error: (error) => {
            this.alertService.pushAlert(new Alert(`There was an error updating the Special Condition Number. Please try again.`, AlertContext.Danger, true));
            }
        });
    }

    private getCanEdit() {
        return this.canEdit
    };

    override edit(params) {
        this.openEditModal(params);
    }

    override navigateToCreatePage() {
        this.openEditModal();
    }

    override customButtonAction() {
        this.modalOpen = true;
    }

    private openEditModal(params?) {
        let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
            data: {
                ID: !!params ? params.node.data.ResearchProjectSpecialConditionID : null,
                dto: !!params ? params.node.data : {Content: ''},
                header: 'Edit Special Condition',
                showActions: true,
                editMode: true,
                formPage: {
                    dtoName: 'research project special condition',
                    elements: [
                        {
                            class: 'g-col-12',
                            name: 'Content',
                            label: 'Condition',
                            type: 'editor',
                            editorConfig: {toolbar: true, menubar: true, plugins: 'lists link table help wordcount'}
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return dto;
                    },
                    put: (id, dto) => this.putAction(id, dto),
                    post: (dto) => this.postAction(dto)
                } as FormPage
            }
        });

        if (dialogRef) {
            dialogRef.afterClosed().subscribe(() => {
                this.refreshData();
            });
        }
    }

}
