import {Component} from '@angular/core';
import {BasicFormComponent, FormPage} from "../../basic/shared/basic-form/basic-form.component";
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {AlertService} from "../../../shared/services/alert.service";
import {ResearchProjectDto} from "../../../shared/generated/model/research-project-dto";


@Component({
    selector: 'castateparksscp-research-project-dpr65b-flag-form',
    templateUrl: './../../basic/shared/basic-form/basic-form.component.html',
})
export class ResearchProjectDpr65bFlagFormComponent extends BasicFormComponent {
    upsertDto: ResearchProjectDto;

    formPage: FormPage = {
        dtoName: 'research project',
        elements: [
            {
                class: 'g-col-7',
                name: 'OptionalLiabilityWaiverAddendumDpr65B',
                label: 'Are all project personnel employed or insured by the institution conducting the research?',
                required: false,
                type: 'radio',
                radioOptions: this.flagOptions,
                onChange: this.handleFlagChange.bind(this)
            },
        ]
    } as FormPage;

    constructor(public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            return { OptionalLiabilityWaiverAddendumDpr65B: dto?.OptionalLiabilityWaiverAddendumDpr65B || false};
        };
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdateAdditionalDocumentationPut(id, upsertDto);
    }
}
