import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BasicFormComponent, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {AlertService} from 'src/app/shared/services/alert.service';
import {ResearchProjectService} from "../../../shared/generated/api/research-project.service";
import {CurrentResearchProjectService} from "../../../services/current-research-project.service";
import {
    ResearchProjectUpdateUserIsThePrincipalInvestigatorDto
} from "../../../shared/generated/model/research-project-update-user-is-the-principal-investigator-dto";

@Component({
    selector: 'castateparksscp-research-team-user-is-principal-investigator-form',
    templateUrl: '../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ResearchTeamUserIsPrincipalInvestigatorForm extends BasicFormComponent implements OnInit {
    @Output() userIsThePrincipalInvestigator = new EventEmitter<any>();

    upsertDto: ResearchProjectUpdateUserIsThePrincipalInvestigatorDto;

    userIsPrincipalInvestigatorRadioOptions: Array<{ text: string, value: any }> = [
        {text: "I am the principal investigator", value: true},
        {text: "I am not the principal investigator", value: false}
    ];

    formPage: FormPage = {
        dtoName: 'researchProject',
        elements: [
            {
                class: 'g-col-7',
                name: 'UserIsThePrincipalInvestigator',
                label: '',
                required: false,
                type: 'radio',
                radioOptions: this.userIsPrincipalInvestigatorRadioOptions,
                onChange: this.userIsThePrincipalInvestigatorChanged.bind(this),
            },
        ]
    } as FormPage;

    constructor(public currentResearchProjectService: CurrentResearchProjectService,
                public researchProjectService: ResearchProjectService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => {
            return { UserIsThePrincipalInvestigator: dto?.UserIsThePrincipalInvestigator }
        }
        this.formPage.post = (upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut(upsertDto);
        this.formPage.put = (id, upsertDto) => this.researchProjectService.researchProjectsResearchProjectIDUpdateUserIsThePrincipalInvestigatorPut(id, upsertDto);
    }

    public userIsThePrincipalInvestigatorChanged(value: boolean): void {
        this.userIsThePrincipalInvestigator.emit(value);
    }
}
