import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {AlertService} from "../../shared/services/alert.service";
import {finalize, forkJoin, throwError} from "rxjs";
import {ResearchProjectContactTypeEnum} from "../../shared/generated/enum/research-project-contact-type-enum";
import {ResearchProjectContactService} from "../../shared/generated/api/research-project-contact.service";
import {ResearchProjectContactDto} from "../../shared/generated/model/research-project-contact-dto";
import {StateIdToDisplayNameMap} from "../../shared/models/enums/state.enum.util";
import {CountryIdToDisplayNameMap} from "../../shared/models/enums/country.enum.util";
import {ResearchProjectFileService} from "../../shared/generated/api/research-project-file.service";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {
    ResearchProjectCategoryTypeIdToDisplayNameMap
} from "../../shared/models/enums/research-project-category.enum.util";
import {ResearchProjectAreaParkService} from "../../shared/generated/api/research-project-area-park.service";
import {ResearchProjectAreaParkSimpleDto} from "../../shared/generated/model/research-project-area-park-simple-dto";
import {ParkService} from "../../shared/generated/api/park.service";
import {catchError, switchMap, tap} from "rxjs/operators";
import {ResearchProjectStatusTypeEnum} from "../../shared/generated/enum/research-project-status-type-enum";
import {
    ResearchProjectBaseComponentComponent
} from "../research-project/shared/research-project-base-component/research-project-base-component.component";
import {
    ResearchProjectFieldInvestigationOccurrenceService
} from "../../shared/generated/api/research-project-field-investigation-occurrence.service";
import {
    ResearchProjectFieldInvestigationOccurrenceSimpleDto
} from "../../shared/generated/model/research-project-field-investigation-occurrence-simple-dto";
import {ResearchProjectSpecimenItemService} from "../../shared/generated/api/research-project-specimen-item.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {
    ReturnedToResearcherNotificationComponent
} from "../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {BasicConfigurableModalComponent} from "../basic/basic-configurable-modal/basic-configurable-modal.component";
import {FormPage} from "../basic/shared/basic-form/basic-form.component";
import {MatDialog} from "@angular/material/dialog";
import {NgForm} from "@angular/forms";
import {Alert} from "../../shared/models/alert";
import {AlertContext} from "../../shared/models/enums/alert-context.enum";
import {
    ResearchProjectFileListManagerComponent
} from "../research-project-file/research-project-file-list-manager/research-project-file-list-manager.component";

@Component({
    selector: 'castateparksscp-research-project-review-and-submit',
    templateUrl: './research-project-review-and-submit.component.html',
    styleUrls: ['./research-project-review-and-submit.component.scss']
})
export class ResearchProjectReviewAndSubmitComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    researchProjectID: number;
    researchProject: ResearchProjectDto;
    contactReview: Array<{field: string, value: string}> = [];
    additionalParticipants = [];
    additionalParticipantsColDefs = [
        {
            headerName: "First Name",
            field: "FirstName",
            editable: true,
            flex: 1,
        },
        {
            headerName: "Last Name",
            field: "LastName",
            editable: true,
            flex: 1,
        },
        {
            headerName: "Phone Number",
            field: "PhoneNumber1",
            editable: true,
            flex: 1,
        },
        {
            headerName: "Email",
            field: "Email",
            editable: true,
            flex: 1,
        }
    ];
    specimenItems = [];
    specimenItemsColDefs = [
        {
            headerName: "Species",
            field: "Species",
            editable: true,
            flex: 1,
        },
        {
            headerName: "Quantity",
            field: "Quantity",
            editable: true,
            flex: 1,
        },
        {
            headerName: "Size",
            field: "Size",
            editable: true,
            flex: 1,
        },
        {
            headerName: "Condition",
            field: "Condition",
            editable: true,
            flex: 2,
        }
    ];
    fileList;
    researchTeamReview = [];
    projectInformationReview = [];
    studyAreaReview = [];
    dataCollectionReview = [];
    additionalDocumentationReview = [];
    parksMap = new Map();

    fileRoute = '';
    isSubmitInProgress = false;
    pageTitle: string = "Review Application";
    renewalsExist: boolean = true;

    @ViewChild("notificationComponent") public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;
    @ViewChild("annualReportManager") public annualReportManager: ResearchProjectFileListManagerComponent;

    constructor(private router: Router,
                public route: ActivatedRoute,
                public cdr: ChangeDetectorRef,
                public alertService: AlertService,
                public researchProjectService: ResearchProjectService,
                public researchProjectContactService: ResearchProjectContactService,
                public researchProjectAreaParkService: ResearchProjectAreaParkService,
                public researchProjectFieldInvestigationOccurrenceService: ResearchProjectFieldInvestigationOccurrenceService,
                public researchProjectSpecimenItemService: ResearchProjectSpecimenItemService,
                public parkService: ParkService,
                public researchProjectFileService: ResearchProjectFileService,
                public dialog: MatDialog,
                private authenticationService: AuthenticationService
    ) {
        super();
    }
    ngOnInit(): void {
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.fileRoute = `researchProjects/${this.researchProjectID}`;
        this.isLoading = true;
        forkJoin({
            researchProject: this.researchProjectService.researchProjectsResearchProjectIDCompleteGet(this.researchProjectID),
            contacts: this.researchProjectContactService.researchProjectContactsResearchProjectResearchProjectIDGet(this.researchProjectID),
            studyAreas: this.researchProjectAreaParkService.researchProjectAreaParksResearchProjectsResearchProjectIDGet(this.researchProjectID),
            parks: this.parkService.parksGet(),
            files: this.researchProjectFileService.researchProjectsResearchProjectIDFilesGet(this.researchProjectID),
            occurrences: this.researchProjectFieldInvestigationOccurrenceService.researchProjectFieldInvestigationOccurrencesResearchProjectsResearchProjectIDGet(this.researchProjectID),
            specimenItems: this.researchProjectSpecimenItemService.researchProjectSpecimenItemsResearchProjectsResearchProjectIDGet(this.researchProjectID),
            user: this.authenticationService.getCurrentUser(),
            renewalExist: this.researchProjectService.researchProjectsResearchProjectIDRenewalsExistGet(this.researchProjectID)
        }).pipe(tap(({researchProject, contacts, studyAreas, parks, files, occurrences, specimenItems, user, renewalExist}) => {
            this.researchProject = researchProject;
            if (researchProject.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.ReturnedToResearcher &&
                researchProject.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft) {
                this.pageTitle = "Application Summary";
            }

            if (researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ActivePermit ||
                researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Expired) {
                this.pageTitle = "Permit Summary";
            }

            this.fileList = files;
            parks.forEach(p => this.parksMap.set(p.ParkID, p.Name));
            this.processContacts(contacts, occurrences);
            this.processSectionBySectionMap(this.researchTeamReview, this.researchTeamFieldMap);
            this.processStudyAreaReview(studyAreas);
            this.processSectionBySectionMap(this.projectInformationReview, this.projectInformationFieldMap);
            this.projectInformationReview.push({field: 'Field Investigation Occurrence',
                value: occurrences.map(x => `${new Date(x.StartDate).toLocaleDateString()} - ${new Date(x.EndDate).toLocaleDateString()}`).join(", ")});
            this.processSectionBySectionMap(this.dataCollectionReview, this.dataCollectionFieldMap);
            this.specimenItems = specimenItems;
            this.processSectionBySectionMap(this.additionalDocumentationReview, this.additionalDocumentationFieldMap);
            this.renewalsExist = renewalExist;
            this.setCanEditPage(user, researchProject);
        }),
            finalize(() => this.isLoading = false))
            .subscribe();
    }

    processContacts(contacts: ResearchProjectContactDto[], occurrences: ResearchProjectFieldInvestigationOccurrenceSimpleDto[]) {
        this.addContactByType(contacts, ResearchProjectContactTypeEnum.Organization, 'Organization');
        this.addContactByType(contacts, ResearchProjectContactTypeEnum.PrincipalInvestigator, 'Principal investigator');
        this.addContactByType(contacts, ResearchProjectContactTypeEnum.PersonInChargeOfFieldWork, 'Person in charge of field work');
        this.additionalParticipants = contacts?.filter(x => ResearchProjectContactTypeEnum.AdditionalParticipant == x.ResearchProjectContactTypeID);
    }

    addContactByType(contacts, contactTypeID: number, field: string) {
        let orgIndex = contacts?.findIndex(r => contactTypeID == r.ResearchProjectContactTypeID);
        const orgContact = {
                field: field,
                value: this.buildContactDisplay(contacts[orgIndex], ResearchProjectContactTypeEnum.Organization == contactTypeID)
            };
        this.contactReview.push(orgContact);
    }

    buildContactDisplay(contact: ResearchProjectContactDto, isOrganization = false): string {
        let displayString = '';
        if (isOrganization && contact?.OrganizationName) displayString += contact?.OrganizationName;
        if (!isOrganization && contact?.FirstName) displayString += contact?.FirstName + " ";
        if (!isOrganization && contact?.LastName) displayString += contact?.LastName;
        displayString += "<br/>";
        if (contact?.PhoneNumber1) displayString  += "<br/>" + contact?.PhoneNumber1;
        if (contact?.Email) displayString  += "<br/>" + contact?.Email;
        if (contact?.AddressLine1) displayString += "<br/><br/>" + contact?.AddressLine1;
        if (contact?.AddressLine2) displayString += "<br/>" + contact?.AddressLine2;
        displayString += "<br/>";
        if (contact?.City) displayString += contact?.City + ", ";
        if (contact?.StateID) displayString += StateIdToDisplayNameMap.get(contact?.StateID);
        if (contact?.PostalCode) displayString += " " + contact.PostalCode;
        if (contact?.CountryID && contact.CountryID != 234) displayString += "<br/>" + CountryIdToDisplayNameMap.get(contact?.StateID);
        return displayString;
    }

    submit(): void {
        this.isSubmitInProgress = true;
        this.researchProjectService.researchProjectsResearchProjectIDFinalSubmitPut(this.researchProjectID)
            .pipe(finalize(() => this.isSubmitInProgress = false))
            .subscribe((researchProject) => {
                this.router.navigateByUrl(`/public/research-project-submission-success/${researchProject.ResearchProjectID}`).then();
            });
    }

    processStudyAreaReview(parks: ResearchProjectAreaParkSimpleDto[]) {
        this.studyAreaReview = [
            {
                field: 'Parks',
                error: [],
                value: parks.map(p => this.parksMap.get(p.ParkID)).join(", ")
            }
        ]
    }

    private getDisplayValue(key, dto){
        if (key == 'ResearchProjectCategoryTypeID') {
            return ResearchProjectCategoryTypeIdToDisplayNameMap.get(dto[key]);
        }
        if (key.toLowerCase().includes('date') && !!dto[key]) {
            return new Date(dto[key]).toLocaleDateString();
        }
        if (dto[key] === true || dto[key] === false) {
            return dto[key] ? 'Yes' : 'No';
        }
        return dto[key];
    }

    processSectionBySectionMap(reviewSection: Array<{field: string, value: any}>, sectionMap: Map<string, string>){
        const fields = Array.from(sectionMap.keys());
        fields.forEach(field => {
            reviewSection.push({
                field: sectionMap.get(field),
                value: this.getDisplayValue(field, this.researchProject)
            })
        });
    }

    withdraw(){
        let dialogRef = this.dialog.open(BasicConfigurableModalComponent, {
            maxWidth: 600,
            data: {
                ID: this.researchProjectID,
                dto: {
                    RejectionMessage: null
                },
                header: 'Confirm Application Withdrawal',
                content: 'Warning: Withdrawing your application is a permanent action. Once withdrawn, your application will be locked and cannot be modified',
                showActions: true,
                editMode: true,
                saveButtonLabel: 'Confirm Withdrawal',
                saveButtonClass: 'danger',
                formPage: {
                    dtoName: 'permit',
                    elements: [
                        {
                            class: 'g-col-12',
                            name: 'WithdrawText',
                            label: 'To confirm, please type "withdraw" in the box below and click "Confirm Withdrawal"',
                            type: 'text',
                            required: true
                        },
                    ],
                    createUpsertDto: (dto) => {
                        return {WithdrawText: null};
                    },
                    put: (id, dto) => {
                        return this.researchProjectService.researchProjectsResearchProjectIDWithdrawPut(id)
                            .pipe(switchMap(() => forkJoin({
                                    project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
                                    internalFields: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID)
                                })),
                                tap((response : {project, internalFields}) => {
                                    this.researchProject = response.project;
                                    this.returnedToResearcherNotification.setInternalFields(response.internalFields);
                                    this.alertService.pushAlert(new Alert(`Permit application was successfully withdrawn.`, AlertContext.Success));
                                }),
                                catchError(err => throwError(err)))

                    },
                } as unknown as FormPage,
                formInvalidCondition: (form: NgForm) => {
                    return form?.form?.get('WithdrawText')?.value?.toLowerCase() != 'withdraw';
                }
            }
        });
    }


    researchTeamFieldMap = new Map([
        ['OptionalLiabilityWaiverAddendumDpr65B', 'Are all project personnel employed or insured by the institution conducting the research?'],
    ]);

    projectInformationFieldMap = new Map([
        ['ResearchProjectCategoryTypeID', 'Project category'],
        ['Title', 'Project title'],
        ['Purpose', 'Project purpose'],
        ['AnticipatedBenefit', 'Anticipated benefits to the State Park System'],
        ['ProjectStartDate', 'Project start date'],
        ['ProjectEndDate', 'Project end date'],
        ['RequestedPermitStartDate', 'Permit requested start date'],
        ['RequestedPermitEndDate', 'Permit requested end date'],
        ['TentativeAnnualReportCompletionDate', 'Annual report tentative completion date'],
    ]);

    dataCollectionFieldMap = new Map([
        ['SpecimenCollection', 'Will your project involve collection of specimens?'],
        ['CollectionRationaleDetails', 'Collection Rationale'],
        ['ProposalIncludesCollectionRationale', 'Collection Rationale answered in proposal'],
        ['CurationFacility', 'Curation facility'],
        ['CurationResponsibleOfficialName', 'Curation facility: Responsible official name'],
        ['CurationPhone', 'Curation facility: Phone'],
        ['CurationEmail', 'Curation facility: Email'],
        ['LaboratoryWork', 'Will your project involve laboratory work?'],
        ['LaboratoryFacility', 'Laboratory facility'],
        ['LaboratoryResponsibleOfficialName', 'Laboratory facility: Responsible official name'],
        ['LaboratoryPhone', 'Laboratory facility: Phone'],
        ['LaboratoryEmail', 'Laboratory facility: Email'],
        ['LabStudyStartDate', 'Lab study start date'],
        ['LabStudyEndDate', 'Lab study end date'],
        ['StudyProceduresDetails', 'Study Procedures'],
        ['ProposalIncludesStudyProcedures', 'Study Procedures answered in proposal'],
        ['DataProductDetails', 'Location of data and data products collected under this permit'],
        ['ProposalIncludesDataProducts', 'Location of data and data products collected under this permit answered in proposal'],
    ]);

    additionalDocumentationFieldMap = new Map([
        ['SoilDisturbance', 'Will your project involve soil disturbance (placement of stakes, collection of soil cores, etc.)?'],
        ['SoilDisturbanceDetails', 'Soil disturbance details'],
        ['ProposalIncludesSoilDisturbance', 'Soil disturbance details answered in proposal'],
        ['AdditionalPermits', 'Will your project require additional federal, state, or local permits?'],
        ['AdditionalPermitsDetails', 'If you replied yes but do not have all the necessary permits prior to submission of this application, provide rationale or additional information'],
        ['BudgetDetails', 'Budget (optional)'],
        ['ProposalIncludesBudget', 'Budget (optional) answered in proposal'],
        ['LiteratureCitedDetails', 'Literature Cited (optional)'],
        ['ProposalIncludesLiteratureCited', 'Literature Cited (optional) answered in proposal'],
        ['OptionalInsuranceAddendumDpr65A', 'Will you conduct or plan on conducting research activities above and beyond simple use? (I.E. Examples of activities beyond simple use include but are not limited to the use of aircraft or drones, boats, high risk vehicle use, scuba diving, climbing equipment, trapping, handling of large and/or dangerous wildlife, and collection of materials that create risk such as large pinecones, projects that include large teams of volunteers, etc.)?'],
    ]);

    isSubmitDisabled() {
        if (this.isSubmitInProgress) return true;
        return this.researchProject?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft
            && this.researchProject?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.ReturnedToResearcher;
    }

    stepIndex: number = 5;
    handleStepSelect(route: string): void {
        this.router.navigateByUrl(route).then();
    }

    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;

    hasPermitStatus() {
        return this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ActivePermit ||
        this.researchProject.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Expired;
    }

    displayWithdrawButton() {
        return this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft ||
            this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.InReview ||
            this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher;
    }

    displaySubmitButton() {
        return this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft ||
            this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher;
    }

    displayRenewButton() {
        if (this.renewalsExist) return false;
        return this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ActivePermit ||
            this.researchProject?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Expired;
    }

    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => this.researchProject = res);
        this.returnedToResearcherNotification.refreshInternalFields();
    }

    renew() {
        this.researchProjectService.researchProjectsResearchProjectIDRenewPost(this.researchProjectID)
            .subscribe({
                next: (response) => this.router.navigateByUrl(`/public/research-teams/${response.ResearchProjectID}`).then(() =>
                    this.alertService.pushAlert(new Alert("A renewal permit was successfully created.", AlertContext.Success, true))),
                error: err => {
                    this.alertService.pushAlert(new Alert("There was an issue renewing this permit.", AlertContext.Danger, true));
                }
            });
    }
}
