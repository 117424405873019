import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResearchProjectFileTypeEnum} from "../../shared/generated/enum/research-project-file-type-enum";
import {ResearchProjectInternalFieldsDto} from "../../shared/generated/model/research-project-internal-fields-dto";
import {ResearchProjectService} from "../../shared/generated/api/research-project.service";
import {finalize, forkJoin, throwError} from "rxjs";
import {
    InternalRevewInternalFieldsFormComponent
} from "./internal-revew-internal-fields-form/internal-revew-internal-fields-form.component";
import {BasicModalComponent} from "../basic/basic-modal/basic-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {ResearchProjectStatusTypeEnum} from "../../shared/generated/enum/research-project-status-type-enum";
import {UnsignedPermitFormComponent} from "./unsigned-permit-form/unsigned-permit-form.component";
import {ResearchProjectFileService} from "../../shared/generated/api/research-project-file.service";
import {ResearchProjectDto} from "../../shared/generated/model/research-project-dto";
import {
    ResearchProjectFileListComponent
} from "../research-project-file/research-project-file-list/research-project-file-list.component";
import {FileService} from "../../shared/services/file/file.service";
import {InlineLoadingState, TableHeaderItem, TableItem, TableModel} from "carbon-components-angular";
import {
    ResearchProjectBaseComponentComponent
} from "../research-project/shared/research-project-base-component/research-project-base-component.component";
import {AlertService} from "../../shared/services/alert.service";
import {AuthenticationService} from "../../services/authentication.service";
import {
    ResearchProjectFileListManagerComponent
} from "../research-project-file/research-project-file-list-manager/research-project-file-list-manager.component";
import {Alert} from "../../shared/models/alert";
import {AlertContext} from "../../shared/models/enums/alert-context.enum";
import {ResearchProjectInternalNotesDto} from "../../shared/generated/model/research-project-internal-notes-dto";
import {InternalNotesFormComponent} from "./internal-notes-form/internal-notes-form.component";
import {UserDto} from "../../shared/generated/model/user-dto";
import {RoleEnum} from "../../shared/generated/enum/role-enum";
import {
    ResearchProjectAssociatedDistrictContactDto
} from "../../shared/generated/model/research-project-associated-district-contact-dto";
import {ResourceCategoryTagFormComponent} from "./resource-category-tag-form/resource-category-tag-form.component";
import {ResearchProjectTagService} from "../../shared/generated/api/research-project-tag.service";
import {ResearchProjectTagDto} from "../../shared/generated/model/research-project-tag-dto";
import {
    ReturnedToResearcherNotificationComponent
} from "../research-project/shared/returned-to-researcher-notification/returned-to-researcher-notification.component";
import {catchError, switchMap} from "rxjs/operators";
import {PermitWorkflowChangeListComponent} from "./permit-workflow-change-list/permit-workflow-change-list.component";
import {ResearchProjectRenewedFromDto} from "../../shared/generated/model/research-project-renewed-from-dto";

@Component({
    selector: 'castateparksscp-internal-review',
    templateUrl: './internal-review.component.html',
    styleUrl: './internal-review.component.css'
})
export class InternalReviewComponent extends ResearchProjectBaseComponentComponent implements OnInit {
    readonly stepIndex = 6;
    researchProjectID: number;
    fileRoute: string;
    protected readonly ResearchProjectFileTypeEnum = ResearchProjectFileTypeEnum;
    internalFieldsDto: ResearchProjectInternalFieldsDto;
    internalNotes: ResearchProjectInternalNotesDto;
    public project: ResearchProjectDto;
    tags: ResearchProjectTagDto[];
    isLoading: boolean;
    isGenerating: boolean = false;
    isApprovalInProgress: boolean = false;
    isMarkAwaitingAnnualReportInProgress: boolean = false;
    public canEdit: boolean = false;
    private currentUser: UserDto;
    annualReportNotificationObject = {};
    renewedFromResearchProject : ResearchProjectRenewedFromDto;

    @ViewChild("internalReviewForm", {static: true})
    public internalReviewForm: InternalRevewInternalFieldsFormComponent;
    @ViewChild("unsignedPermitForm", {static: true})
    public unsignedPermitForm: UnsignedPermitFormComponent;
    @ViewChild("additionalInformationRequestedModal", {static: true})
    public additionalInformationModal: BasicModalComponent;
    @ViewChild("signedPermitFileManager", {static: true})
    public signedPermitFileManager: ResearchProjectFileListManagerComponent;
    @ViewChild("researchProjectFileList", {static: true})
    public researchProjectFileManager: ResearchProjectFileListManagerComponent;
    @ViewChild("internalNotesForm", {static: true})
    public internalNotesForm: InternalNotesFormComponent;
    @ViewChild("tagForm", {static: true})
    public tagForm: ResourceCategoryTagFormComponent;
    @ViewChild("notificationComponent", {static: true})
    public returnedToResearcherNotification: ReturnedToResearcherNotificationComponent;
    @ViewChild("workflowChangesList", {static: true})
    public workflowChangesList: PermitWorkflowChangeListComponent;
    errorState = InlineLoadingState.Hidden;
    loadingState = InlineLoadingState.Active;
    districtContacts: ResearchProjectAssociatedDistrictContactDto[];
    restrictedStatuses = [ResearchProjectStatusTypeEnum.ActivePermit,
        ResearchProjectStatusTypeEnum.Expired, ResearchProjectStatusTypeEnum.Rejected,
        ResearchProjectStatusTypeEnum.Withdrawn];
    constructor(private researchProjectService: ResearchProjectService,
                private alertService: AlertService,
                private router: Router,
                public route: ActivatedRoute,
                public cdr: ChangeDetectorRef,
                public researchProjectFileService: ResearchProjectFileService,
                public researchProjectTagService: ResearchProjectTagService,
                private fileService: FileService,
                public dialog: MatDialog,
                public authenticationService: AuthenticationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.researchProjectID = parseInt(this.route.snapshot.paramMap.get("researchProjectID"));
        this.fileRoute = `researchProjects/${this.researchProjectID}`;

        forkJoin({
            currentUser: this.authenticationService.getCurrentUser(),
            internal: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID),
            internalNotes: this.researchProjectService.researchProjectsResearchProjectIDInternalNotesGet(this.researchProjectID),
            project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
            districtContacts: this.researchProjectService.researchProjectsResearchProjectIDAssociatedDistrictContactsGet(this.researchProjectID),
            renewedFromResearchProject: this.researchProjectService.researchProjectsResearchProjectIDRenewedFromResearchProjectGet(this.researchProjectID)
        })
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(({currentUser, project, internal, internalNotes, districtContacts, renewedFromResearchProject}) => {
                this.currentUser = currentUser;
                this.project = project;
                this.internalFieldsDto = internal;
                this.internalNotes = internalNotes;
                this.districtContacts = districtContacts;

                let model = new TableModel();
                model.header = [
                    new TableHeaderItem({
                        data: "District Staff Assigned",
                    }),
                    new TableHeaderItem({
                        data: "District",
                    })
                ];
                let t = [];
                districtContacts.forEach(dto => {
                    t.push(
                        [new TableItem({data: dto.Name}), new TableItem({data: dto.DistrictName})]
                    );
                });
                model.data = [...t];

                this.cdsTable.model = model;
                this.renewedFromResearchProject = renewedFromResearchProject;
                this.annualReportNotificationObject = {
                    type: 'error',
                    title: `Annual Report for Original Permit: ${renewedFromResearchProject?.ApplicationNumber} (${renewedFromResearchProject?.Title}) not yet submitted.`,
                    message: `Due ${new Date(renewedFromResearchProject?.DueDate)?.toLocaleDateString()}, Researcher estimated date for submission is ${new Date(renewedFromResearchProject?.RequestedDueDate)?.toLocaleDateString()}.`,
                    lowContrast: true,
                    showClose: false
                }
                this.setCanEdit();
            });
    }

    cdsTable = {
        title: 'Assigned District Contacts',
        description: 'District contacts associated with the parks selected for application.',
        stickyHeader: true,
        model: null,
        batchText: '',
        size: 'sm',
    }

    approve(): void {
        this.isApprovalInProgress = true;
        this.researchProjectService.researchProjectsResearchProjectIDApprovePut(this.researchProjectID)
            .pipe(switchMap(() => forkJoin({
                project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
                internalFields: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID)
            })),
                catchError(err => throwError(err)))
            .subscribe({
                next: response => {
                    this.project = response.project;
                    this.internalFieldsDto = response.internalFields;
                    this.returnedToResearcherNotification.setInternalFields(response.internalFields);
                    this.setCanEdit();
                    this.workflowChangesList.refreshData();
                    this.isApprovalInProgress = false;
                    this.alertService.pushAlert(new Alert(`The permit was successfully approved.`, AlertContext.Success));
                },
                error: err => this.isApprovalInProgress = false
            })
    }

    internalFieldsSaved(dto: ResearchProjectInternalFieldsDto) {
        this.internalFieldsDto = dto;
        this.setCanEdit();
    }

    saveInternalFields(): void {
        this.internalReviewForm.save();
    }

    setCanEdit(): void {
        // Cannot edit if the permit is active, rejected, withdrawn (expired too?)
        if (this.restrictedStatuses.includes(this.internalFieldsDto?.ResearchProjectStatusTypeID)) {
            this.canEdit = false;
            return;
        }

        // If the project has been returned to researcher or is in review, the assigned reviewer or system admin can edit
        if (this.internalFieldsDto?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher
            || this.internalFieldsDto?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.InReview
            || this.internalFieldsDto?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.AwaitingAnnualReport
            ) {
            this.canEdit = this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([1]) ||
                this.internalFieldsDto?.TechnicalReviewerUserID == this.currentUser.UserID ||
                this.internalFieldsDto.CurrentUserIsAssociatedDistrictStaff;
        }
    }

    generate() {
        this.isGenerating = true;
        this.errorState = InlineLoadingState.Active;
        if (this.isRestrictedStatusOrApprovalInProgress()) {
            this.generateUnsignedPermit(this.project);
            return;
        }
        this.unsignedPermitForm.save();
    }

    generateUnsignedPermit(updatedDto: ResearchProjectDto) {
        if (!updatedDto) {
            this.isGenerating = false;
            this.errorState = InlineLoadingState.Hidden;
            return;
        }
        this.project = updatedDto;
        this.researchProjectFileService.researchProjectFilesResearchProjectIDUnsignedFinalLetterGet(this.researchProjectID)
            .subscribe({
                next: response => {
                    this.isGenerating = false;
                    this.errorState = InlineLoadingState.Hidden;
                    ResearchProjectFileListComponent.downloadFile(this.fileService, this.fileRoute, response);
                },
                error: err => {
                    this.errorState = InlineLoadingState.Error;
                }
            });
    }

    isApproveButtonDisabled() {
        if (this.isRestrictedStatusOrApprovalInProgress()) return true;
        if (this.renewedFromResearchProject?.AnnualReportMissing) return true;
        return !this.signedPermitFileManager.uploadedFilesExist();
    }

    isRestrictedStatusOrApprovalInProgress() {
        if (this.restrictedStatuses.includes(this.project?.ResearchProjectStatusTypeID)) return true;
        return !!this.isApprovalInProgress;
    }

    handleSignedPermitUpload() {
        this.researchProjectFileManager.refreshData();
    }

    handleStepSelect(route: string): void {
        this.router.navigateByUrl(route).then(x => {});
    }

    refreshInternalNotes($event: any) {
        this.internalNotes = $event;
        this.internalNotesForm.editMode = false;
    }

    enterInternalNotesEditMode() {
        this.internalNotesForm.editMode = true;
    }

    showInternalNotesEditButton() {
        if (!this.internalNotesForm) return false;
        return !this.internalNotesForm.editMode;
    }

    handleInternalNotesEditCancel() {
        this.internalNotesForm.editMode = false;
    }

    canEditInternalNotes() {
        if (!this.project) return true;
        if (this.project.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft) return true;
        if (this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([RoleEnum.SystemAdmin])) return false;
        return this.internalFieldsDto?.TechnicalReviewerUserID != this.currentUser.UserID;
    }

    handleTagSave(value) {
        this.tagForm.ngOnInit();
    }
    handleTagFormChanged(value) {
        this.tagForm.save();
    }
    handleTagRemove(researchProjectTagID) {
        this.researchProjectTagService.researchProjectTagsResearchProjectTagIDDelete(researchProjectTagID)
            .subscribe(() => this.tagForm.ngOnInit());
    }

    handleStatusChangeEvent(): void {
        this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID)
            .subscribe((res: ResearchProjectDto) => {
                this.project = res;
            });
        this.returnedToResearcherNotification.refreshInternalFields();
        this.workflowChangesList.refreshData();
    }

    displayGenerateButton() {
        return this.project?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Withdrawn &&
            this.project?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Rejected &&
            this.project?.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft;
    }

    isGenerateButtonDisabled() {
        if (this.isGenerating) return true;
        if (!this.canEdit) return true;
        return this.unsignedPermitForm?.form?.invalid;
    }

    getGenerateButtonLabel() {
        if (this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher
            || this.project?.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.InReview) {
            return "Save Dates and Generate Unsigned Permit";
        }
        return "Generate Unsigned Permit";
    }

    handleSpecialConditionsEditEvent() {
        this.workflowChangesList.refreshData();
    }

    markAwaitingAnnualReport() {
        this.isMarkAwaitingAnnualReportInProgress = true;
       this.researchProjectService.researchProjectsResearchProjectIDMarkAwaitingAnnualReportPut(this.researchProjectID)
           .pipe(switchMap(() => forkJoin({
                   project: this.researchProjectService.researchProjectsResearchProjectIDGet(this.researchProjectID),
                   internalFields: this.researchProjectService.researchProjectsResearchProjectIDInternalFieldsGet(this.researchProjectID)
               })),
               catchError(err => throwError(err)))
           .subscribe({
               next: response => {
                   this.project = response.project;
                   this.internalFieldsDto = response.internalFields;
                   this.returnedToResearcherNotification.setInternalFields(response.internalFields);
                   this.setCanEdit();
                   this.workflowChangesList.refreshData();
                   this.isMarkAwaitingAnnualReportInProgress = false;
                   this.alertService.pushAlert(new Alert(`The permit was successfully marked as Awaiting Annual Report.`, AlertContext.Success));
               },
               error: err => this.isMarkAwaitingAnnualReportInProgress = false
           })
    }

    isMarkAwaitingAnnualReportButtonDisabled() {
        if (this.isMarkAwaitingAnnualReportInProgress) return true;
        if (this.project.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.AwaitingAnnualReport) return true;
        return this.renewedFromResearchProject?.AnnualReportMissing;
    }
}
