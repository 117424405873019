<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="project"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <div style="margin-top:30px; margin-bottom:25px; display: flex; justify-content: space-between">
            <h2 style="margin: 0">Internal Review<span *ngIf="!!project?.RenewedFromPermitNumber"> - Renewal</span></h2>
        </div>
        <div *ngIf="renewedFromResearchProject?.AnnualReportMissing" style="margin-bottom: 1rem;">
            <cds-inline-notification [notificationObj]="annualReportNotificationObject"></cds-inline-notification>
        </div>
        <castateparksscp-returned-to-researcher-notification #notificationComponent
                                                             [researchProjectID]="researchProjectID" [alwaysDisplay]="true"></castateparksscp-returned-to-researcher-notification>

        <castateparksscp-internal-revew-internal-fields-form
            #internalReviewForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="internalFieldsDto"
            [ID]="researchProjectID"
            [editMode]="canEdit"
            [isHideActionButtons]="true"
            (formSubmitted)="internalFieldsSaved($event)"
            (formChanged)="saveInternalFields()"
            [canEditTechnicalReview]="canEdit"
        ></castateparksscp-internal-revew-internal-fields-form>

        <div class="grid-12">
            <div class="g-col-7">
                <cds-table *ngIf="!!cdsTable.model"
                           [model]="cdsTable.model"
                           [size]="cdsTable.size"
                           [enableSingleSelect]="false"
                           [showSelectionColumn]="false"
                           [striped]="false"
                           [sortable]="false"
                           [isDataGrid]="true"
                           [skeleton]="false"
                           [style]="'margin-top: 10px'"
                ></cds-table>
            </div>
        </div>

        <br/>
        <castateparksscp-resource-category-tag-form
            #tagForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="internalFieldsDto"
            [researchProjectID]="researchProjectID"
            [editMode]="true"
            [isHideActionButtons]="true"
            [displayDefaultSuccessAlert]="false"
            (formSubmitted)="handleTagSave($event)"
            (formChanged)="handleTagFormChanged($event)"
            [canEditTechnicalReview]="true"
        ></castateparksscp-resource-category-tag-form>
        <cds-tag-filter *ngFor="let tag of tagForm.getResearchProjectTags()"
            [type]="'cool-gray'"
            [size]="'md'"
            title="Filter"
            closeButtonLabel="Clear"
            (close)="handleTagRemove(tag.ResearchProjectTagID)">
            {{tag.Content}}
        </cds-tag-filter>


        <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
        <h3 style="margin-bottom: 10px">Permit Approval</h3>
        <h4 style="margin-top: 0">1: Set permit dates, CEQA, and print permit</h4>
        <div class="grid-12">
            <div class="g-col-6">
                <castateparksscp-unsigned-permit-form
                    #unsignedPermitForm
                    [isLoading]="isLoading"
                    [isCDS]="true"
                    [dto]="project"
                    [ID]="researchProjectID"
                    [editMode]="canEdit && !isApprovalInProgress"
                    [isHideActionButtons]="true"
                    (formSubmitted)="generateUnsignedPermit($event)">
                    ></castateparksscp-unsigned-permit-form>
            </div>
            <div class="g-col-6" style="margin-top:20px; margin-bottom:30px; float:right;">
                <div *ngIf="displayGenerateButton()" style="display: flex; width: 100%">
                    <button (click)="generate()" [disabled]="isGenerateButtonDisabled()" cdsButton="secondary" size="md"
                            style="margin-right: 10px">{{getGenerateButtonLabel()}}
                    </button>
                    <cds-inline-loading [state]="errorState"
                                        [loadingText]="'Generating…'"
                                        [errorText]="'Generation failed'">
                    </cds-inline-loading>
                </div>
            </div>
        </div>
        <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
        <h4>2: Upload signed permit and mark as approved</h4>
        <div class="grid-12">
            <div class="g-col-6">
                <castateparksscp-research-project-file-list-manager
                    #signedPermitFileManager
                    [uploadFileButtonText]="'Upload Signed Active Permit*'"
                    [limitOneFile]="true"
                    [canDeleteFile]="canEdit"
                    [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.SignedActivePermit"
                    [researchProjectID]="researchProjectID"
                    (uploadedFileChangeEmitted)="handleSignedPermitUpload()"
                    [disabled]="!canEdit"
                ></castateparksscp-research-project-file-list-manager>
            </div>
            <div class="g-col-6" style="margin-top:20px; margin-bottom:30px; float:right;">
                <div *ngIf="!!project?.RenewedFromPermitNumber" style="margin-bottom: 1rem; display: flex; width: 100%">
                    <ng-container *ngIf="!!canEdit">
                        <button cdsButton [disabled]="isMarkAwaitingAnnualReportButtonDisabled()" (click)="markAwaitingAnnualReport()">Mark Awaiting Annual Report</button>
                    </ng-container>
                </div>
                <div style="display: flex; width: 100%">
                    <ng-container *ngIf="!!canEdit">
                        <button size="xl" style="margin-right: 10px" cdsButton [disabled]="isApproveButtonDisabled()" (click)="approve()">Approve permit</button>
                        <cds-inline-loading *ngIf="isApprovalInProgress"
                                            [state]="loadingState"
                                            [loadingText]="'Approval in progress…'">
                        </cds-inline-loading>
                    </ng-container>
                </div>
            </div>
        </div>

        <h4>Form Documents</h4>

        <castateparksscp-research-project-file-list
            #researchProjectFileList
            [researchProjectID]="researchProjectID"
            [researchProjectFileTypeID]="null"
            [fileRoute]="fileRoute"
            [viewAllFiles]="true"
            [useDefaultViewAllFilesHeight]="false"
            [disabled]="true"
        ></castateparksscp-research-project-file-list>

        <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
        <div class="grid-12">
            <div class="g-col-4"><h4 style="margin: 0">Internal Notes</h4></div>
            <div class="g-col-4" style="display:flex; flex-direction: row-reverse">
                <button *ngIf="showInternalNotesEditButton()" [disabled]="canEditInternalNotes()" cdsButton="secondary"
                        type="button" title="Edit" (click)="enterInternalNotesEditMode()">Edit
                </button>
            </div>
            <div class="g-col-8">
                <castateparksscp-internal-notes-form
                    #internalNotesForm
                    [isLoading]="isLoading"
                    [isCDS]="true"
                    [dto]="internalNotes"
                    [ID]="researchProjectID"
                    [isHideActionButtons]="false"
                    (cancelEditModeChange)="handleInternalNotesEditCancel()"
                    (formSubmitted)="refreshInternalNotes($event)">
                </castateparksscp-internal-notes-form>
            </div>
            <div class="g-col-12">
                <castateparksscp-research-project-special-conditions-grid
                    (editEvent)="handleSpecialConditionsEditEvent()"
                    [disabled]="!canEdit"
                    [researchProjectID]="researchProjectID"
                ></castateparksscp-research-project-special-conditions-grid>
            </div>
            <div class="g-col-12">
                <castateparksscp-research-project-district-special-conditions-grid
                    (editEvent)="handleSpecialConditionsEditEvent()"
                    [disabled]="!canEdit"
                    [researchProjectID]="researchProjectID">
                </castateparksscp-research-project-district-special-conditions-grid>
            </div>
        </div>

        <div style="width: 100%; border-bottom: 1px solid lightgrey; margin-top: 30px; margin-bottom: 25px"></div>
        <castateparksscp-permit-workflow-change-list #workflowChangesList
                                                     [researchProjectID]="researchProjectID"></castateparksscp-permit-workflow-change-list>

    </div>
</div>

