import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {NotFoundComponent} from "./pages";
import {HeaderNavComponent} from "./components";
import {UnauthenticatedComponent} from "./pages/unauthenticated/unauthenticated.component";
import {SubscriptionInsufficientComponent} from "./pages/subscription-insufficient/subscription-insufficient.component";
import {NgProgressModule} from "ngx-progressbar";
import {RouterModule} from "@angular/router";
import {LinkRendererComponent} from "./components/ag-grid/link-renderer/link-renderer.component";
import {
    FontAwesomeIconLinkRendererComponent
} from "./components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component";
import {MultiLinkRendererComponent} from "./components/ag-grid/multi-link-renderer/multi-link-renderer.component";
import {CustomRichTextComponent} from "./components/custom-rich-text/custom-rich-text.component";
import {FieldDefinitionComponent} from "./components/field-definition/field-definition.component";
import {
    FieldDefinitionGridHeaderComponent
} from "./components/field-definition-grid-header/field-definition-grid-header.component";
import {
    CustomPinnedRowRendererComponent
} from "./components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import {CustomDropdownFilterComponent} from "./components/custom-dropdown-filter/custom-dropdown-filter.component";
import {CsvDownloadButtonComponent} from "./components/csv-download-button/csv-download-button.component";
import {
    ClearGridFiltersButtonComponent
} from "./components/clear-grid-filters-button/clear-grid-filters-button.component";
import {ContextMenuRendererComponent} from "./components/ag-grid/context-menu/context-menu-renderer.component";
import {FooterNavComponent} from "./footer-nav/footer-nav.component";
import {DropdownToggleDirective} from "./directives/dropdown-toggle.directive";
import {PopperDirective} from "./directives/popper.directive";
import {PopperComponent} from './components/popper/popper.component';
import {EditorModule, TINYMCE_SCRIPT_SRC} from "@tinymce/tinymce-angular";
import {ConfirmDialog} from "./components/confirm-dialog/confirm-dialog.component";
import {EsaMaterialButtonComponent} from "esa-material-form-field";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatMenuModule} from "@angular/material/menu";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatChipsModule} from "@angular/material/chips";
import {CustomFormLabelComponent} from "./components/custom-form-label/custom-form-label.component";
import {TinymceEditorComponent} from './components/tinymce-editor/tinymce-editor.component';
import {FileUploaderComponent} from "./components/file-uploader/file-uploader.component";
import {
    OrderEditButtonRendererComponent
} from './components/ag-grid/order-edit-button-renderer/order-edit-button-renderer.component';
import {
    InLineEditButtonsRendererComponent
} from './components/ag-grid/in-line-edit-buttons-renderer/in-line-edit-buttons-renderer.component';
import {HtmlRendererComponent} from './components/ag-grid/html-renderer/html-renderer.component';
import {
    TruncatedTextRendererComponent
} from './components/ag-grid/truncated-text-renderer/truncated-text-renderer.component';
import {
    ButtonModule,
    DropdownModule,
    FileUploaderModule, IconModule, InlineLoadingModule,
    InputModule,
    LinkModule,
    TilesModule
} from "carbon-components-angular";
import {LogoComponent} from './components/logo/logo.component';
import {
    CustomMultiSelectEditorComponent
} from './components/ag-grid/custom-multi-select-editor/custom-multi-select-editor.component';
import { CustomBasicSelectEditorComponent } from './components/ag-grid/custom-basic-select-editor/custom-basic-select-editor.component';
import { RightArrowComponent } from './components/carbon-icon/right-arrow/right-arrow.component';
import { RequiredHeaderComponent } from './components/ag-grid/required-header/required-header.component';

@NgModule({
    declarations: [
        HeaderNavComponent,
        NotFoundComponent,
        UnauthenticatedComponent,
        SubscriptionInsufficientComponent,
        LinkRendererComponent,
        FontAwesomeIconLinkRendererComponent,
        MultiLinkRendererComponent,
        CustomRichTextComponent,
        FieldDefinitionComponent,
        FieldDefinitionGridHeaderComponent,
        CustomPinnedRowRendererComponent,
        CustomDropdownFilterComponent,
        CsvDownloadButtonComponent,
        ClearGridFiltersButtonComponent,
        ContextMenuRendererComponent,
        FooterNavComponent,
        DropdownToggleDirective,
        PopperDirective,
        PopperComponent,
        ConfirmDialog,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        FileUploaderComponent,
        OrderEditButtonRendererComponent,
        InLineEditButtonsRendererComponent,
        HtmlRendererComponent,
        TruncatedTextRendererComponent,
        LogoComponent,
        CustomMultiSelectEditorComponent,
        CustomBasicSelectEditorComponent,
        RightArrowComponent,
        RequiredHeaderComponent,
    ],
    imports: [
        CommonModule,
        FormsModule.withConfig({
            callSetDisabledState: "whenDisabledForLegacyCode",
        }),
        HttpClientModule,
        NgProgressModule,
        RouterModule,
        EditorModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        NgxMaterialTimepickerModule,
        MatMenuModule,
        EsaMaterialButtonComponent,
        LinkModule,
        TilesModule,
        ButtonModule,
        DropdownModule,
        FileUploaderModule,
        InputModule,
        IconModule,
        InlineLoadingModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        NotFoundComponent,
        HeaderNavComponent,
        CustomRichTextComponent,
        FieldDefinitionComponent,
        FieldDefinitionGridHeaderComponent,
        CsvDownloadButtonComponent,
        ClearGridFiltersButtonComponent,
        ContextMenuRendererComponent,
        FooterNavComponent,
        PopperDirective,
        EditorModule,
        ConfirmDialog,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        FileUploaderComponent,
        LogoComponent,
        RightArrowComponent,
    ],
    providers: [
        {
            provide: TINYMCE_SCRIPT_SRC,
            useValue: "assets/tinymce/tinymce.min.js",
        },
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }

    static forChild(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
