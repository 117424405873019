<form class="form grid-12 custom-cds-form" #basicForm="ngForm">
    <ng-container *ngIf="isCDS">
        <ng-container *ngFor="let e of formPage.elements">
            <div class="{{e.class}}" *ngIf="!isHidden(e.hidden)">
                <ng-container *ngIf="e.type == 'subHeading'">
                    <h4>{{e.label}}</h4>
                </ng-container>

                <ng-container *ngIf="e.type == 'displayText'">
                    <cds-text-label *ngIf="!!e.showCondition ? e.showCondition() : true">
                        <span [ngClass]="{'text-info': !!e.textInfo}">{{ !!e.labelTextGetter ? e.labelTextGetter() : e.label}}</span>
                    </cds-text-label>
                </ng-container>

                <ng-container *ngIf="e.type == 'text'">
                    <cds-text-label [invalid]="!!errors?.[e.name]"
                                    [invalidText]="errors?.[e.name] || ''"
                                    [warn]="!!getRequiredWarning(e)"
                                    [warnText]="getRequiredWarning(e)">
                        <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                        <input
                            name="{{e.name}}"
                            [disabled]="editMode && e.disabled"
                            [readonly]="!editMode"
                            [required]="e.required"
                            [(ngModel)]="upsertDto[e.name]"
                            [invalid]="!!errors[e.name]"
                            (blur)="clearExistingErrorMessages(e.name)"
                            cdsText>
                    </cds-text-label>
                </ng-container>

                <ng-container *ngIf="e.type == 'phoneNumber'">
                    <cds-text-label [invalid]="!!errors?.[e.name]"
                                    [invalidText]="errors?.[e.name] || ''"
                                    [warn]="!!getRequiredWarning(e)"
                                    [warnText]="getRequiredWarning(e)">
                        <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                        <input name="{{e.name}}"
                               (keydown.space)="handlePhoneNumberSpace($event)"
                               [disabled]="editMode && e.disabled"
                               [invalid]="!!errors?.[e.name]"
                               [readonly]="!editMode"
                               [required]="e.required"
                               [(ngModel)]="upsertDto[e.name]"
                               maxlength="25"
                               (blur)="handlePhoneNumberBlur(e.name)"
                               cdsText>
                    </cds-text-label>
                </ng-container>

                <ng-container *ngIf="e.type == 'textArea'">
                    <div *ngIf="e.checkbox" style="width:100%; display: inline-flex;">
                        <div style="flex-grow: 1">
                            <cds-text-label class="custom-cds-label" [invalid]="!!errors?.[e.name]"
                                            [invalidText]="errors?.[e.name] || ''">
                                <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                            </cds-text-label>
                        </div>
                        <div style="flex-grow: 0">
                            <cds-checkbox
                                [disabled]="!editMode"
                                [checked]="!!upsertDto[e.checkboxName]"
                                (checkedChange)="onTextAreaCheckboxChange(e.checkboxName, $event, e.name)"
                                [hideLabel]="false">
                                {{e.checkboxLabel || 'Answered in study proposal'}}
                            </cds-checkbox>
                        </div>
                    </div>

                    <cds-textarea-label
                        [helperText]="e.helperText"
                        [invalid]="showTextAreaInvalidText(textArea.value, e.name)"
                        [invalidText]="getTextAreaInvalidText(textArea.value, e)">
                        <ng-container *ngIf="!e.checkbox">
                            <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                        </ng-container>
                        <textarea
                            #textArea
                            name="{{e.name}}"
                            [readonly]="!editMode"
                            [disabled]="upsertDto[e.checkboxName]"
                            [required]="e.required"
                            [invalid]="showTextAreaInvalidText(textArea.value, e.name)"
                            [(ngModel)]="upsertDto[e.name]"
                            [rows]="6"
                            [cols]="200"
                            (blur)="clearExistingErrorMessages(e.name)"
                            cdsTextArea
                            class="textarea-field" >
                        </textarea>
                    </cds-textarea-label>
                    <div *ngIf="!!getTextAreaWarningText(textArea.value, e)" class="cds--form-requirement custom-cds-warning-text">
                        {{getTextAreaWarningText(textArea.value, e)}}
                    </div>
                </ng-container>

                <ng-container *ngIf="e.type == 'number'">
                    <cds-number
                        name="{{e.name}}"
                        [label]="e.label + (e.required ? '*':'')"
                        [size]="'md'"
                        [step]="1"
                        [readonly]="!editMode"
                        [(ngModel)]="upsertDto[e.name]"
                    ></cds-number>
                </ng-container>

                <ng-container *ngIf="e.type == 'date'">
                    <label class="cds--label" for="{{e.name}}">
                        <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                    </label>
                    <cds-date-picker name="{{e.name}}"
                                     [required]="e.required"
                                     [invalid]="!!errors?.[e.name]"
                                     [invalidText]="errors?.[e.name] || ''"
                                     [warn]="!!getRequiredWarning(e)"
                                     [warnText]="getRequiredWarning(e)"
                                     [helperText]="getHelperText(e)"
                                     [disabled]="!editMode"
                                     (valueChange)="runOnDateChange(e.onChange, $event, e.name)"
                                     [(ngModel)]="upsertDto[e.name]"
                    ></cds-date-picker>
                </ng-container>

                <ng-container *ngIf="e.type == 'select' && editMode">
                    <div [hidden]="!!e.showCondition ? !e.showCondition() : false">
                        <label class="cds--label" for="{{e.name}}">
                            <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                        </label>
                        <cds-select
                            [disabled]="!!e.disableCondition ? e.disableCondition() : e.disabled"
                            name="{{e.name}}"
                            [(ngModel)]="upsertDto[e.name]"
                            (change)="runSelectOnChange(e.onChange, this)"
                        >
                            <option value="default" disabled selected hidden>Choose an option</option>
                            <ng-container *ngFor="let option of e.selectOptions">
                                <option value="{{option.value}}" >{{option.text}}</option>
                            </ng-container>
                        </cds-select>
                    </div>
                </ng-container>
                <ng-container *ngIf="e.type == 'select' && !editMode">
                    <div [hidden]="!!e.showCondition ? !e.showCondition() : false">
                        <cds-text-label>
                            <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                            <input
                                name="{{e.name}}"
                                readonly
                                [(ngModel)]="upsertDto[e.selectDisplayFieldName]"
                                cdsText>
                        </cds-text-label>
                    </div>
                </ng-container>

                <ng-container *ngIf="e.type == 'radio'">
                    <cds-radio-group *ngIf="!e.hidden"
                        name="{{e.name}}"
                        [(ngModel)]="upsertDto[e.name]"
                        ariaLabel="radiogroup"
                        [orientation]="'vertical'"
                        (change)="runOnChange(e.onChange, $event)"
                                     [disabled]="!editMode"
                    >
                        <legend [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}" class="cds--label ng-star-inserted">{{e.label}}</legend>
                        <ng-container *ngFor="let o of e.radioOptions">
                            <cds-radio [value]="o.value">{{o.text}}</cds-radio>
                            <div *ngIf="!!o.helperText" class="cds--form__helper-text" style="margin-block-end: .5rem">
                                {{o.helperText}}
                            </div>
                        </ng-container>
                    </cds-radio-group>
                </ng-container>


                <div class="{{e.class}}" *ngIf="e.type == 'editor'">
                    <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
                        <cds-text-label *ngIf="e.type == 'editor'">
                            {{ e.label + (e.required ? '*':'')}}
                            <div class="value" *ngIf="editMode">
                                <div class="grid-12">
                                    <div class="g-col-12">
                                        <tinymce-editor #tinyMceEditor name="{{e.name}}"
                                                        [overrideConfig]="e.editorConfig"
                                                        [(content)]="upsertDto[e.name]"></tinymce-editor>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 100%; background: #faf9f5; padding: 1rem"
                                [innerHTML]="upsertDto[e.name] === null || upsertDto[e.name] === undefined ? '' : (upsertDto[e.name] | bypassHtml)"
                                class="value-display"
                                *ngIf="!editMode"></div>
                        </cds-text-label>
                    </div>
                </div>

                <ng-container *ngIf="e.type == 'multipleCheckboxes'">
                    <legend class="cds--label"><span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span></legend>
                    <a style="cursor: pointer; margin-right: .5rem" (click)="selectAllMultiCheckboxes(e.name)" cdsLink [inline]="true"><small>Select all</small></a><a style="cursor: pointer" (click)="deselectAllMultiCheckboxes(e.name)" cdsLink [inline]="true"><small>Deselect all</small></a>
                    <table style="width: 100%; table-layout: fixed">
                        <td *ngFor="let itemColumn of itemColumns.get(e.name)">
                            <ul>
                                <li *ngFor="let item of itemColumn" style="padding-bottom: 2px; padding-top: 2px;">
                                    <cds-checkbox
                                        [disabled]="!editMode"
                                        [checked]="getCheckboxValue(e.name, item)"
                                        (checkedChange)="onCheckboxChange(e.name, item)"
                                        [hideLabel]="!item[e.itemLabel]">
                                        {{item[e.itemLabel]}}
                                    </cds-checkbox>
                                </li>
                            </ul>
                        </td>
                    </table>
                </ng-container>

                <ng-container *ngIf="e.type == 'multiselect' && editMode">
                    <cds-dropdown
                        name="{{e.name}}"
                        type="multi"
                        selectionFeedback="fixed"
                        [(ngModel)]="upsertDto[e.name]"
                        [label]="e.label + (e.required ? '*':'')"
                        [helperText]="e.helperText"
                        [dropUp]="false"
                        placeholder="Select"
                        [disabled]="!!e.disableCondition ? e.disableCondition() : e.disabled"
                        [itemValueKey]="'value'">
                        <cds-dropdown-list [items]="e.selectOptions"></cds-dropdown-list>
                    </cds-dropdown>
                </ng-container>
                <ng-container *ngIf="e.type == 'multiselect' && !editMode">
                        <cds-textarea-label
                            [helperText]="e.helperText"
                        >
                            <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                            <textarea
                                name="{{e.name}}"
                                [readonly]="true"
                                [ngModel]="getMultiSelectReadOnlyValue(e)"
                                [rows]="3"
                                cdsTextArea
                                class="textarea-field">
                            </textarea>
                        </cds-textarea-label>
                </ng-container>

                <ng-container *ngIf="e.type == 'filterSelect' && editMode">
                    <cds-text-label [invalid]="!!errors?.[e.name]"
                                    [invalidText]="errors?.[e.name] || ''">
                        <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                    </cds-text-label>
                    <cds-combo-box
                        #filterSelect
                        [required]="e.required"
                        name="{{e.name}}"
                        [(ngModel)]="e.listItemValue"
                        [disabled]="!!e.disableCondition ? e.disableCondition() : e.disabled"
                        [size]="'sm'"
                        [appendInline]="false"
                        [helperText]="e.helperText"
                        [items]="e.listItems"
                        [warn]="!!getRequiredWarning(e)"
                        [warnText]="getRequiredWarning(e)"
                        [type]="e.selectType || 'multi'"
                        selectionFeedback="fixed"
                        [invalid]="!!errors?.[e.name]"
                        (selected)="runOnMultiComboboxChange(e.onChange, $event, e.name)"
                        (clear)="runOnComboboxClear(e.onChange, e.name)">
                        <cds-dropdown-list></cds-dropdown-list>
                    </cds-combo-box>
                    <ul cdsList *ngIf="e.displayFilterSelectedItems" style="margin-top: 0.5rem"> Selected:
                        <li cdsListItem *ngFor="let l of e.listItemValue">{{l.content}}</li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="e.type == 'filterSelect' && !editMode">
                    <span>{{e.label}}</span>
                    <ul cdsList *ngIf="e.displayFilterSelectedItems" style="margin-top: 0.5rem">
                        <li cdsListItem *ngFor="let l of e.listItemValue">{{l.content}}</li>
                    </ul>
                </ng-container>

                <ng-container *ngIf="e.type == 'combobox' && editMode">
                    <cds-text-label class="cds--combo-box-custom"
                                    [invalid]="!!errors?.[e.name]"
                                    [invalidText]="errors?.[e.name] || ''">
                        <span [ngClass]="{'custom-required': !!e.required || !!e.requiredValidated}">{{e.label}}</span>
                    </cds-text-label>
                    <cds-combo-box
                        name="{{e.name}}"
                        [(ngModel)]="e.listItemValue"
                        [disabled]="!!e.disableCondition ? e.disableCondition() : e.disabled"
                        [size]="'sm'"
                        [appendInline]="false"
                        [helperText]="e.helperText"
                        [items]="e.listItems"
                        [warn]="!!getRequiredWarning(e)"
                        [warnText]="getRequiredWarning(e)"
                        [placeholder]="e.placeholder || 'Filter...'"
                        type="single"
                        selectionFeedback="top-after-reopen"
                        [invalid]="!!errors?.[e.name]"
                        (submit)="handleComboboxSubmit($event, e.name, e.newListItemName, e.onChange)"
                        (selected)="runOnSingleComboboxChange(e.onChange, $event, e.name)"
                        (clear)="runOnComboboxClear(e.onChange, e.name)">
                        <cds-dropdown-list></cds-dropdown-list>
                    </cds-combo-box>
                </ng-container>

                <ng-container *ngIf="e.type == 'checkbox'" style="width:100%; display: inline-flex;">
                    <cds-checkbox
                        [name]="e.name"
                        [(ngModel)]="upsertDto[e.name]"
                        >
                        {{e.label}}
                    </cds-checkbox>
                </ng-container>

                <!--TODO Money-->
                <!--TODO Map-->
            </div>
            <div class="g-col-12" *ngIf="e.conditionalHelperTextArray">
                <ng-container *ngFor="let element of e.conditionalHelperTextArray">
                    <cds-text-label *ngIf="!!element.showCondition ? element.showCondition() : true">
                        <span [ngClass]="{'text-info': !!element.textInfo}">{{ !!element.textGetter ? element.textGetter() : element.text}}</span>
                    </cds-text-label>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!isCDS">
        <ng-container *ngFor="let e of formPage.elements">
            <div class="{{e.class}}" *ngIf="e.type == 'text'">
                <esa-material-ff
                    name="{{e.name}}"
                    [editMode]="editMode"
                    [required]="true"
                    type="text"
                    [(ngModel)]="upsertDto[e.name]"
                    [customLabel]="customLabel">
                </esa-material-ff>
                <ng-template #customLabel>
                    <castateparksscp-custom-form-label label="{{e.label}}"
                                                       fieldDefinitionType="{{e.fieldDefinitionType}}"></castateparksscp-custom-form-label>
                </ng-template>
            </div>

            <div class="{{e.class}}" *ngIf="e.type == 'date'">
                <esa-material-ff
                    name="{{e.name}}"
                    [editMode]="editMode"
                    type="date"
                    [(ngModel)]="upsertDto[e.name]"
                    [customLabel]="customLabel">
                </esa-material-ff>
                <ng-template #customLabel>
                    <castateparksscp-custom-form-label label="{{e.label}}"
                                                       fieldDefinitionType="{{e.fieldDefinitionType}}"></castateparksscp-custom-form-label>
                </ng-template>
            </div>

            <div class="{{e.class}}" *ngIf="e.type == 'editor'">
                <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
                    <castateparksscp-custom-form-label label="{{e.label}}"
                                                       fieldDefinitionType="{{e.fieldDefinitionType}}"></castateparksscp-custom-form-label>
                    <div class="value" *ngIf="editMode">
                        <div class="grid-12">
                            <div class="g-col-12">
                                <tinymce-editor #tinyMceEditor name="{{e.name}}"
                                                [overrideConfig]="e.editorConfig"
                                                [(content)]="upsertDto[e.name]"></tinymce-editor>
                            </div>
                        </div>
                    </div>
                    <div
                        [innerHTML]="upsertDto[e.name] === null ? '-' : (upsertDto[e.name] | bypassHtml)"
                        class="value-display"
                        *ngIf="!editMode"></div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <div class="g-col-12 actions-bar" *ngIf="editMode && !isHideActionButtons" style="margin-top: 1rem">
        <ng-container *ngIf="isCDS; else materialButtons">
            <button type="button" cdsButton="ghost" [title]="'Cancel'" (click)="cancelEditMode()">Cancel</button>
            <button type="button" cdsButton="primary" [title]="!basicForm.form.valid ? 'You are missing required fields.' : ''"
                    [disabled]="!basicForm.form.valid" (click)="saveForm(form)">Save
            </button>
        </ng-container>
        <ng-template #materialButtons>
            <esa-material-button
                style="margin-right: 1rem"
                type="primary"
                label="Save"
                icon="save"
                [tooltip]="!basicForm.form.valid ? 'You are missing required fields.' : ''"
                (click)="saveForm(form)"
                [disabled]="!basicForm.form.valid">
            </esa-material-button>

            <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEditMode()"></esa-material-button>
        </ng-template>
    </div>
</form>
