import {ResearchProjectDto} from "../../../../shared/generated/model/research-project-dto";
import {ResearchProjectAreaParkListDto} from "../../../../shared/generated/model/research-project-area-park-list-dto";
import {
    ResearchPermitPublicFormProgressIndicatorComponent
} from "../../../research-permit-public-form-progress-indicator/research-permit-public-form-progress-indicator.component";
import {UserDto} from "../../../../shared/generated/model/user-dto";
import {RoleEnum} from "../../../../shared/generated/enum/role-enum";
import {ResearchProjectStatusTypeEnum} from "../../../../shared/generated/enum/research-project-status-type-enum";
import {Router} from "@angular/router";

export abstract class ResearchProjectBaseComponentComponent {
    isLoading: boolean = false;
    stepSelectedRoute: string = '';
    canEditPage: boolean = false;
    abstract stepIndex: number;
    abstract handleStepSelect(route: string): void;
    abstract handleStatusChangeEvent(): void;

    public incompleteSectionsExcludingReviewAndSubmitExist(researchProject: ResearchProjectDto| ResearchProjectAreaParkListDto) {
        if (!researchProject) return true;
        if (!researchProject.IncompleteSectionNumbers) return false;
        return !!researchProject.IncompleteSectionNumbers
            .filter(x => x != ResearchPermitPublicFormProgressIndicatorComponent.REVIEW_AND_SUBMIT_STEP_INDEX).
            length;
    }

    public setCanEditPage(userDto: UserDto, projectDto: ResearchProjectDto, router: Router = null) {
        if (userDto.Role.RoleID == RoleEnum.Public
            || (projectDto.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.ReturnedToResearcher
                && projectDto.ResearchProjectStatusTypeID != ResearchProjectStatusTypeEnum.Draft)) {
            this.canEditPage = projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft ||
                projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher;
            if (!!router) this.redirectIfNonEditable(userDto, projectDto, router);
        } else {
            this.canEditPage = true;
        }
    }

    private redirectIfNonEditable(userDto: UserDto, projectDto: ResearchProjectDto, router: Router) {
        if (!projectDto || !userDto) return;
        if (userDto.Role.RoleID == RoleEnum.Public &&
            (projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.Draft
                || projectDto.ResearchProjectStatusTypeID == ResearchProjectStatusTypeEnum.ReturnedToResearcher)) {
            return;
        }
        let url = `/public/research-project-review-and-submit/${projectDto.ResearchProjectID}`;
        router.navigateByUrl(url)
            .then();
    }
}
