import {Component} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {AgGridEventService} from "../../../../services/ag-grid-event.service";

@Component({
  selector: 'castateparksscp-custom-basic-select-editor',
  templateUrl: './custom-basic-select-editor.component.html',
  styleUrl: './custom-basic-select-editor.component.css'
})
export class CustomBasicSelectEditorComponent implements ICellEditorAngularComp {
    public params: any;
    public selectedValue: any;

    constructor(private agGridEventService: AgGridEventService) {
    }

    agInit(params: any): void {
        this.params = params;
        this.selectedValue = params.value;
        this.agGridEventService.updateValue(this.selectedValue);
    }

    getValue(): any {
        return this.selectedValue;
    }

    onChange(event) {
        if (this.params.colDef.cellEditorParams.isListenedTo) {
            this.agGridEventService.updateValue(event.target.value);
        }
    }
}
