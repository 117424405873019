import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {BasicFormComponent, FormElement, FormPage} from 'src/app/pages/basic/shared/basic-form/basic-form.component';
import {CurrentContactService} from 'src/app/services/current-contact.service';
import {ContactService} from 'src/app/shared/generated/api/contact.service';
import {ContactUpsertDto} from 'src/app/shared/generated/model/contact-upsert-dto';
import {CountryIdAndDisplayNameArray} from 'src/app/shared/models/enums/country.enum.util';
import {StateIdAndDisplayNameArrayUS} from 'src/app/shared/models/enums/state.enum.util';
import {AlertService} from 'src/app/shared/services/alert.service';
import {buildStateIdAndDisplayName} from "../../../../shared/models/enums/state.util";

@Component({
    selector: 'castateparksscp-contact-form',
    templateUrl: '../../../basic/shared/basic-form/basic-form.component.html',
    styleUrls: ['../../../basic/shared/basic-form/basic-form.component.scss'],
})
export class ContactFormComponent extends BasicFormComponent implements OnInit {
    @Input() isPublicUser: boolean = false;

    upsertDto: ContactUpsertDto;
    stateOptions: Array<{ text: string, value: number }> = StateIdAndDisplayNameArrayUS;
    countryOptions: Array<{ text: string, value: number }> = CountryIdAndDisplayNameArray;
    uneditableFields: string[] = ['FirstName', 'LastName', 'Email'];

    formPage: FormPage = {
        dtoName: 'contact',
    } as FormPage;

    constructor(public currentContactService: CurrentContactService,
                public contactService: ContactService,
                public alertService: AlertService) {
        super(alertService)
        this.formPage.createUpsertDto = (dto) => this.currentContactService.createContactDto(dto);
        this.formPage.post = (upsertDto) => this.contactService.contactsPost(upsertDto);
        this.formPage.put = (id, upsertDto) => this.contactService.contactsContactIDPut(id, upsertDto);
    }

    ngOnInit(): void {
        this.formPage.elements.forEach(e => {
            if (e.name == 'Comment' || e.name == 'DistrictID' || e.name == 'Cc') e.hidden = this.isPublicUser;
        })
        super.ngOnInit();
    }

    static updateStateList(c: BasicFormComponent, updateStateID = true) {
        const CountryID = c.upsertDto["CountryID"];
        const e: Array<FormElement> = c.formPage.elements.filter(e => e.name == 'StateID');
        const stateIdAndDisplayName = buildStateIdAndDisplayName(CountryID);
        const label = CountryID == 234 ? 'State' : 'State/Province';
        e.forEach(e => {
            e.selectOptions = stateIdAndDisplayName;
            e.label = label;
        });
        if (updateStateID) c.upsertDto["StateID"] = stateIdAndDisplayName[0]?.value;
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        this.refreshFormElements();
    }
    override handleSaveFormError(err) {
        this.errors = err;
        this.formSubmitted.emit();
    }

    buildFormElements(isAccessRestricted): FormElement[] {
         let elements = [
            {
                class: 'g-col-6',
                name: 'FirstName',
                label: 'First Name',
                required: false,
                fieldDefinitionType: 'FirstName',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'LastName',
                label: 'Last Name',
                required: false,
                fieldDefinitionType: 'LastName',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'PhoneNumber1',
                label: 'Phone Number',
                required: false,
                type: 'phoneNumber',
            },
            {
                class: 'g-col-6',
                name: 'Email',
                label: 'Email',
                required: false,
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'CountryID',
                label: 'Country',
                required: false,
                fieldDefinitionType: 'Country',
                selectDisplayFieldName: 'CountryDisplayName',
                type: 'select',
                selectOptions: this.countryOptions,
                onChange: ContactFormComponent.updateStateList,
            },
            {
                class: 'g-col-6',
                name: 'StateID',
                label: 'State',
                required: false,
                fieldDefinitionType: 'State',
                selectDisplayFieldName: 'StateDisplayName',
                type: 'select',
                selectOptions: this.stateOptions,
            },
            {
                class: 'g-col-6',
                name: 'AddressLine1',
                label: 'Address Line 1',
                required: false,
                fieldDefinitionType: 'AddressLine1',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'AddressLine2',
                label: 'Address Line 2',
                required: false,
                fieldDefinitionType: 'AddressLine2',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'City',
                label: 'City',
                required: false,
                fieldDefinitionType: 'City',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'PostalCode',
                label: 'Zip Code',
                required: false,
                fieldDefinitionType: 'PostalCode',
                type: 'text',
            },
            {
                class: 'g-col-6',
                name: 'Title',
                label: 'Title',
                required: false,
                type: 'text',
            },
        ] as FormElement[];
         this.uneditableFields.forEach(x => {
             let index = elements.findIndex(e => e.name == x);
             if (index > -1) elements[index].disabled = isAccessRestricted
         });
         return elements;
    }

    refreshFormElements(isAccessRestricted: boolean = false) {
        super.refreshFormElements(isAccessRestricted);
        if (this.upsertDto?.CountryID) {
            ContactFormComponent.updateStateList(this, false);
        }
    }
}
