<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="researchProject"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div id="review" class="g-col-10">
        <h2>{{ this.pageTitle }}</h2>
        <p style="padding-inline-start: 1rem; margin-bottom: 1rem" *ngIf="!!researchProject?.RenewedFromPermitNumber">Renewed from: {{researchProject?.RenewedFromPermitNumber}}</p>
        <ng-container *ngIf="incompleteSectionsExcludingReviewAndSubmitExist(researchProject); else complete">
            <p *ngIf="!isLoading && !researchProject">This research project permit application is not yet ready for review. Please go back and complete all
                unfinished sections.</p>
        </ng-container>
        <ng-template #complete>
            <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>
            <castateparksscp-research-project-file-list-manager
                *ngIf="hasPermitStatus()"
                #annualReportManager
                [limitOneFile]="true"
                [uploadFileButtonText]="'Upload Annual Report'"
                [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.AnnualReport"
                [researchProjectID]="researchProjectID"
            ></castateparksscp-research-project-file-list-manager>

            <cds-accordion [align]="'end'" [size]="'lg'">
                <cds-accordion-item title="Research Team">
                    <castateparksscp-review-section [reviewRows]="contactReview"
                                                    [hideButtons]="!canEditPage"
                                                    buttonRoute="/public/research-teams/{{researchProjectID}}"></castateparksscp-review-section>
                    <div *ngIf="additionalParticipants?.length" class="review-tile" style="border-top: none">
                        <p>Additional Participants</p>
                        <!--                        TODO remove if error display no longer needed-->
                        <!--                        <p *ngIf="additionalParticipantsInvalid"><svg style="fill: #da1e28" class="cds&#45;&#45;btn__icon" cdsIcon="warning" size="16"></svg>-->
                        <!--                            Information is incomplete: First name, last name, and email are all required.</p>-->
                        <div class="table-responsive" style="height: 150px">
                            <ag-grid-angular
                                #Grid
                                style="width: 100%; height: 100%"
                                class="ag-theme-balham"
                                [rowData]="additionalParticipants"
                                [columnDefs]="additionalParticipantsColDefs"
                                [suppressMenuHide]="true"
                                [readOnlyEdit]="false">
                            </ag-grid-angular>
                        </div>
                    </div>
                </cds-accordion-item>
                <cds-accordion-item title="Project Information">
                    <castateparksscp-review-section
                        [reviewRows]="projectInformationReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-projects/{{researchProjectID}}"></castateparksscp-review-section>
                </cds-accordion-item>
                <cds-accordion-item title="Study Areas">
                    <castateparksscp-review-section
                        [reviewRows]="studyAreaReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-project-study-areas/{{researchProjectID}}"></castateparksscp-review-section>
                </cds-accordion-item>
                <cds-accordion-item title="Data Collection">
                    <castateparksscp-review-section
                        [reviewRows]="dataCollectionReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-project-data-collection/{{researchProjectID}}"></castateparksscp-review-section>
                    <div *ngIf="specimenItems?.length" class="review-tile" style="border-top: none">
                        <p>Types of specimen to be collected</p>
                        <div class="table-responsive" style="height: 150px">
                            <ag-grid-angular
                                style="width: 100%; height: 100%"
                                class="ag-theme-balham"
                                [rowData]="specimenItems"
                                [columnDefs]="specimenItemsColDefs"
                                [suppressMenuHide]="true"
                                [readOnlyEdit]="false">
                            </ag-grid-angular>
                        </div>
                    </div>
                </cds-accordion-item>
                <cds-accordion-item title="Additional Documentation">
                    <castateparksscp-review-section
                        [reviewRows]="additionalDocumentationReview"
                        [hideButtons]="!canEditPage"
                        buttonRoute="/public/research-project-documents/{{researchProjectID}}"></castateparksscp-review-section>
                </cds-accordion-item>
            </cds-accordion>

            <h6>Uploaded Files</h6>
            <castateparksscp-research-project-file-list
                #researchProjectFileList
                [researchProjectID]="researchProjectID"
                [researchProjectFileTypeID]="null"
                [fileRoute]="fileRoute"
                [viewAllFiles]="true"
                [useDefaultViewAllFilesHeight]="false"
                [disabled]="!canEditPage"
            ></castateparksscp-research-project-file-list>
            <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; display: flex; justify-content: space-between">
                <div>
                    <button *ngIf="displayRenewButton()" cdsButton="ghost" (click)="renew()">Renew permit</button>
                </div>
                <div>
                    <button *ngIf="displayWithdrawButton()" cdsButton="ghost" (click)="withdraw()">Withdraw application</button>
                    <button *ngIf="displaySubmitButton()" cdsButton (click)="submit()" [disabled]="isSubmitDisabled()">Submit application</button>
                </div>
            </div>
        </ng-template>
    </div>

</div>

