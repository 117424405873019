import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PermissionEnum} from '../shared/generated/enum/permission-enum';
import {RightsEnum} from '../shared/models/enums/rights.enum';
import {AuthenticationService} from './authentication.service';
import {ResearchProjectContactSimpleDto, ResearchProjectContactUpsertDto, UserDto} from '../shared/generated/model/models';


@Injectable({
  providedIn: 'root'
})
export class CurrentResearchProjectContactService {
  currentResearchContact$ = new BehaviorSubject<ResearchProjectContactSimpleDto>(null);
  readonly DEFAULT_COUNTRY_ID = 234; // default to US
  constructor(private authenticationService: AuthenticationService) {}

  setCurrentResearchContact(researchContact: ResearchProjectContactSimpleDto): void {
      this.currentResearchContact$.next(researchContact);
  }

  getCurrentResearchContact(): Observable<any> {
      return this.currentResearchContact$.asObservable();
  }

  createResearchProjectContactDto(researchContact: ResearchProjectContactUpsertDto) {
      const researchContactDto = new ResearchProjectContactUpsertDto({
          ResearchProjectContactID: researchContact?.ResearchProjectContactID,
          ResearchProjectID: researchContact?.ResearchProjectID,
          ContactID: researchContact.ContactID,
          ResearchProjectContactTypeID: researchContact?.ResearchProjectContactTypeID,
          FirstName: researchContact?.FirstName,
          LastName: researchContact?.LastName,
          OrganizationName: researchContact?.OrganizationName,
          PhoneNumber1: researchContact?.PhoneNumber1,
          AddressLine1: researchContact?.AddressLine1,
          AddressLine2: researchContact?.AddressLine2,
          City: researchContact?.City,
          StateID: researchContact?.StateID,
          CountryID: researchContact?.CountryID || this.DEFAULT_COUNTRY_ID,
          PostalCode: researchContact?.PostalCode,
          Title: researchContact?.Title,
          Comment: researchContact?.Comment,
          Email: researchContact?.Email,
      });
      return researchContactDto;
  }

  canEditCurrentResearchContact(currentUser: UserDto, researchContact: ResearchProjectContactSimpleDto): boolean {
      return this.authenticationService.hasPermission(currentUser, PermissionEnum.ContactRights, RightsEnum.Update);
  }
}
