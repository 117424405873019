import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output, QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {TinymceEditorComponent} from 'src/app/shared/components/tinymce-editor/tinymce-editor.component';
import {Alert} from 'src/app/shared/models/alert';
import {AlertContext} from 'src/app/shared/models/enums/alert-context.enum';
import {AlertService} from 'src/app/shared/services/alert.service';
import {ComboBox, List, ListItem} from 'carbon-components-angular';

@Component({
    selector: 'castateparksscp-basic-form',
    templateUrl: './basic-form.component.html',
    styleUrl: './basic-form.component.scss'
})
export class BasicFormComponent implements OnInit, OnChanges {
    @ViewChild("basicForm", {read: NgForm}) form: NgForm;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    @Output() formSubmitted = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();
    @Output() textAreaCheckboxChange = new EventEmitter<{name: string, value: any}>();
    @Output() flagChanged = new EventEmitter<any>();

    @Input() ID: number;
    @Input() dto: any;
    @Input() editMode: boolean = false;
    @Input() isCDS: boolean = false;
    @Input() isHideActionButtons: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() displayDefaultSuccessAlert: boolean = true;

    upsertDto: any;
    @Input() formPage: FormPage;
    itemColumns = new Map<string, any>();
    @Input() errors = {};
    readonly TEXT_AREA_CHARACTER_LIMIT = 4000;

    @ViewChildren('filterSelect') filterSelectComponents: QueryList<ComboBox>;

    flagOptions: Array<{ text: string, value: any }> = [
        {text: "Yes", value: true},
        {text: "No", value: false}
    ];

    constructor(public alertService: AlertService) {
    }

    ngOnInit(): void {
        if (this.dto) {
            this.setForm();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['dto']) {
            this.dto = changes['dto'].currentValue;
            this.setForm();
        }
    }

    setForm() {
        this.upsertDto = this.formPage.createUpsertDto(this.dto);
    }

    setBlankForm() {
        this.upsertDto = this.formPage.createUpsertDto(null);
    }

    saveForm(form: NgForm) {
        if (this.isCDS) {
            this.doCDSPreprocessing();
        }
        if (!!this.ID) {
            this.formPage.put(this.ID, this.upsertDto)
                .subscribe({
                    next: (result) => {
                        if (this.displayDefaultSuccessAlert) {
                            this.alertService.pushAlert(new Alert(`The ${this.formPage.dtoName} was successfully updated.`, AlertContext.Success), 5000);
                        }
                        this.formSubmitted.emit(result);
                    },
                    error: (err) => {
                        this.handleSaveFormError(this.getFormErrorFromHttpErrorResponse(err));
                    }
                });
        } else {
            this.formPage.post(this.upsertDto)
                .subscribe({
                    next: (result) => {
                        this.formSubmitted.emit(result);
                        if (this.displayDefaultSuccessAlert) {
                            this.alertService.pushAlert(new Alert(`The ${this.formPage.dtoName} was successfully created.`, AlertContext.Success), 5000);
                        }
                    },
                    error: (err) => {
                        this.handleSaveFormError(this.getFormErrorFromHttpErrorResponse(err));
                    }
                });
        }
    }

    save() {
        this.saveForm(this.form);
    }

    doCDSPreprocessing() {
        this.formPage.elements.forEach(e => {
            //CDS Date Picker saves date as an Array. We need to get the first element of the Array if the date was set.
            if (e.type == 'date' && !!this.upsertDto[e.name]) {
                if (Array.isArray(this.upsertDto[e.name])) {
                    this.upsertDto[e.name] = this.upsertDto[e.name][0]
                }
            }
        })
    }

    getUtcDate(dateValue) {
        if (!dateValue) return null;
        if (!(dateValue instanceof Date)) dateValue = new Date(dateValue);
        const year = dateValue.getUTCFullYear();
        const month = dateValue.getUTCMonth();
        const date = dateValue.getUTCDate();
        return new Date(year, month, date);
    }


    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    onCheckboxChange(name, item){}

    getCheckboxValue(name, item){
        return false;
    }

    selectAllMultiCheckboxes(name: string) {}
    deselectAllMultiCheckboxes(name: string) {}

    clearExistingErrorMessages(name: string) {
        this.errors[name] = undefined;
    }

    handleSaveFormError(err) {
        this.errors = err;
        this.formSubmitted.emit(null);
    }

    isHidden(hidden: boolean) {
        if (hidden == undefined) return false;
        return hidden;
    }

    runOnChange(onChange: Function, event: any): void {
        if (onChange) {
            onChange(event.value);
        }
    }

    runSelectOnChange(onChange: Function, c: BasicFormComponent): void {
        if (onChange) {
            onChange(c);
        }
    }

    getMultiSelectReadOnlyValue(element: FormElement) {
        return this.upsertDto[element.name]?.map(value => element.selectOptions?.filter(option => value == option.value)[0].text)
            .join(', ') || '';
    }

    setFormPageElementProperty(name, property, value) {
        let index = this.formPage.elements.findIndex(x => x.name == name);
        this.formPage.elements[index][property] = value;
    }

    runOnDateChange(onChange: Function, event: any[], name) {
        // Carbon date picker component emits an array so pass null if it's empty
        if (onChange) {
            onChange(event?.length ? event : null);
        }
        this.clearExistingErrorMessages(name);
    }

    runOnMultiComboboxChange(onChange: Function, event: any, name: string): void {
        let value = event instanceof Array ? event?.map(e => e.value) : event.value;
        this.upsertDto[name] = value || [];
    }

    runOnSingleComboboxChange(onChange: Function, event: any, name: string): void {
        this.clearExistingErrorMessages(name);
        this.upsertDto[name] = event?.value || null;
        if (!!onChange) onChange(this.upsertDto);
    }
    runOnComboboxClear(onClear: Function, name: string) {
        this.upsertDto[name] = null;
    }

    getTextAreaWarningText(value: string, e: FormElement) {
        let requiredWarning = this.getRequiredWarning(e);
        if (!!requiredWarning)
            return requiredWarning;
        return value?.length > this.TEXT_AREA_CHARACTER_LIMIT - 300 &&
        value?.length <= this.TEXT_AREA_CHARACTER_LIMIT ? `Current characters: ${value?.length}/4000` : '';
    }

    showTextAreaInvalidText(value: string, name: string) {
        if (!!this.errors[name]) return true;
        return value?.length > this.TEXT_AREA_CHARACTER_LIMIT;
    }

    getTextAreaInvalidText(value: string, e: FormElement) {
        if (!!this.errors[e.name]) return this.errors[e.name];
        return this.showTextAreaInvalidText(value, e.name) ? `Exceeds character limit: ${value?.length}/4000` : '';
    }

    public refreshFormElements(isAccessRestricted = false) {
        this.formPage.elements = this.buildFormElements(isAccessRestricted);
    }

    buildFormElements(isAccessRestricted) {
        return [] as FormElement[];
    }

    mapDtoValueToListItem(property: string, displayField: string = null) {
        if (!this.upsertDto[property]) return null;
        return this.upsertDto[property].map((i: any) => !!displayField
            ? Object.assign(i, {'content': i[displayField]}) : {'content': i, 'value': i});
    }

    handlePhoneNumberSpace(e) {
        e.preventDefault();
    }

    handlePhoneNumberBlur(name: string) {
        this.clearExistingErrorMessages(name);
        this.upsertDto[name] = this.upsertDto[name]?.replace(/\s/g, '');
    }

    mapUpsert(dto) {
        if (this.isCDS) {
            this.doCDSPreprocessing();
        }
        return Object.assign(dto, this.upsertDto);
    }

    onTextAreaCheckboxChange(checkboxName, event, name) {
        this.upsertDto[checkboxName] = event;
        if (event) {
            this.upsertDto[name] = null;
        }
        this.textAreaCheckboxChange.emit({name: checkboxName, value: event});
    }

    handleFlagChange(event){
        this.flagChanged.emit(event);
    }

    getRequiredWarning(e: FormElement) {
        if (this.isLoading) return '';
        if (!!this.upsertDto?.[e.checkboxName]) return '';
        if (e.requiredValidated && (!this.upsertDto?.[e.name] ||
            (this.upsertDto?.[e.name] instanceof Array && !this.upsertDto?.[e.name]?.length))) {
            return `${e.label} is required`;
        }
        return '';
    }

    getSaveFormRequest() {
        if (this.isCDS) {
            this.doCDSPreprocessing();
        }
        if (!!this.ID) {
            return this.formPage.put(this.ID, this.upsertDto)
        }
        return this.formPage.post(this.upsertDto);
    }

    getFormErrorFromHttpErrorResponse(err) {
        return err?.error?.errors ? err.error.errors : err?.error ? err.error : err;
    }

    emitFormSubmitResponse(response) {
        this.formSubmitted.emit(response);
    }

    getHelperText(e: FormElement) {
        if (e.helperTextGetter)
            return e.helperTextGetter();
        return e.helperText;
    }

    handleComboboxSubmit(event, name, newListItemName, onChange) {
        this.clearExistingErrorMessages(name);
        if (!newListItemName) return;
        if (event?.items?.findIndex(x => x.content == event.value.content) == -1) {
            this.upsertDto[newListItemName] = event?.value?.content || null;
            if (!!onChange) onChange(this.upsertDto);
        }
    }

    clearFilterSelectFormElements() {
        if (this.filterSelectComponents?.length) {
            this.filterSelectComponents.forEach(f => f.clearInput(new KeyboardEvent('keydown', { key: 'Enter' })));
        }
    }
}

export interface FormPage {
    elements: Array<FormElement>;
    createUpsertDto: (dto: any) => any;
    post: (upsertDto: any) => Observable<any>;
    put: (id: number, upsertDto: any) => Observable<any>;
    dtoName: string;
}

export interface FormElement {
    selectType?: 'single'| 'multi';
    conditionalHelperTextArray?: any;
    class: string;
    name: string;
    label: string;
    required: boolean;
    requiredValidated: boolean;
    disabled: boolean;
    editable: boolean;
    fieldDefinitionType: string;
    type: 'text' | ' number' | 'editor' | 'date' | 'select' | 'subHeading' | 'multipleCheckboxes' | 'textArea'
        | 'radio'| 'multiselect' | 'phoneNumber' | 'filterSelect' | 'combobox' | 'checkbox';
    placeholder?: string;
    helperText: string;
    selectOptions: Array<{ text: string, value: number }> | Array<ListItem>;
    selectDisplayFieldName: string;
    radioOptions: Array<{ text: string, value: any, helperText?: string }>;
    itemLabel: string;
    hidden: boolean;
    onChange: () => any;
    textInfo?: boolean;
    showCondition?: () => boolean;
    disableCondition?: () => boolean;
    listItems?: ListItem[];
    listItemValue?: ListItem[];
    displayFilterSelectedItems?: boolean
    onClear?: () => any;
    checkbox?: boolean;
    checkboxLabel?: string;
    checkboxName?: string;
    helperTextGetter?: () => string;
    labelTextGetter?: () => string;
    editorConfig?: object;
    newListItemName?: string;
}
