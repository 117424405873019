<div class="grid-12" style="margin-inline: 35px;">
    <div class="g-col-2">
        <castateparksscp-research-permit-public-form-progress-indicator
            [researchProjectID]="researchProjectID"
            [researchProject]="researchProject"
            [current]="stepIndex"
            (stepSelectedEvent)="handleStepSelect($event)"
            (statusChangeEvent)="handleStatusChangeEvent()">
        </castateparksscp-research-permit-public-form-progress-indicator>
    </div>
    <div class="g-col-10">
        <h2>Study Areas</h2>
        <div *ngIf="displayDistrictWarning()" style="margin-bottom: 1rem;">
            <cds-inline-notification [notificationObj]="{
                type: 'error',
                title: 'Cannot submit single-district application.',
                message: 'This application is only for multi-district permits. If you need a single-district permit, please contact the district directly.',
                lowContrast: true,
                showClose: false
            }"></cds-inline-notification>
        </div>
        <castateparksscp-returned-to-researcher-notification #notificationComponent [researchProjectID]="researchProjectID"></castateparksscp-returned-to-researcher-notification>

        <castateparksscp-research-project-study-area-form
            #researchProjectStudyAreaForm
            [isLoading]="isLoading"
            [isCDS]="true"
            [dto]="researchProjectAreaParks"
            [ID]="researchProjectID"
            [editMode]="canEditPage"
            [isHideActionButtons]="true"
            (formSubmitted)="researchProjectStudyAreaSaved($event)">
        </castateparksscp-research-project-study-area-form>

        <castateparksscp-research-project-file-list-manager
            [uploadFileButtonText]="'Upload GIS Location'"
            [researchProjectFileTypeID]="ResearchProjectFileTypeEnum.StudyAreaSupportingDocumentation"
            [researchProjectID]="researchProjectID"
            [disabled]="!canEditPage"
        ></castateparksscp-research-project-file-list-manager>

        <div class="g-col-12" style="margin-top:20px; margin-bottom:30px; float:right;">
            <button cdsButton size="xl" (click)="saveAndContinue()">Save and Continue
                <castateparksscp-right-arrow></castateparksscp-right-arrow>
            </button>
        </div>
    </div>

</div>
