import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserDto} from "../../../shared/generated/model/user-dto";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthenticationService} from "../../../services/authentication.service";
import {Subscription} from "rxjs";
import {BasicFormComponent, FormPage} from "../shared/basic-form/basic-form.component";

@Component({
  selector: 'castateparksscp-basic-configurable-modal',
  templateUrl: './basic-configurable-modal.component.html',
  styleUrl: './basic-configurable-modal.component.css'
})
export class BasicConfigurableModalComponent implements OnInit, OnDestroy {
    @ViewChild("form", {read: BasicFormComponent}) formComponent: BasicFormComponent;
    public dto: any;
    public currentUser: UserDto;
    public editMode: boolean = false;
    public ID: number;
    user: Subscription;
    showActions = true;
    formPage: FormPage;
    saveButtonLabel: string = 'Save';
    saveButtonClass: string = 'primary';
    formInvalidCondition;
    isLoadingSave;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef
    ) {
        this.dto = data.dto;
        this.ID = data.ID;
        this.editMode = data.editMode;
        this.formPage = data.formPage;
        this.showActions = data.showActions;
        if (!!data.saveButtonLabel) this.saveButtonLabel = data.saveButtonLabel;
        if (!!data.saveButtonClass) this.saveButtonClass = data.saveButtonClass;
        if (!!data.formInvalidCondition) this.formInvalidCondition = data.formInvalidCondition;
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
    }

    saveButtonClicked(result) {
        this.isLoadingSave = true;
        this.formComponent.saveForm(null);
    }


    handleFormSubmitEvent(result) {
        this.isLoadingSave = false;
        this.dialogRef.close(true);
    }

    isFormInvalid() {
        if (this.isLoadingSave) return true;
        if (!!this.formInvalidCondition) {
            return this.formInvalidCondition(this.formComponent?.form) || this.formComponent?.form?.invalid;
        }
        return this.formComponent?.form?.invalid;
    }

    canEdit() {
        return this.editMode;
    }

}
